import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
      path: '/home',
      title: 'sidebar.home-title',
      icon: '',
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      onlyForAdmin :false,
      onlyForDistributionTablesUser: false,
      onlyForEAuctionUser : true
    },
    {
      path: '/judgements',
      title: 'sidebar.judgements-title',
      icon: '',
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      onlyForAdmin :false,
      onlyForDistributionTablesUser: true,
      onlyForEAuctionUser : false
    },
    {
      path: '/transfers',
      title: 'sidebar.reports-title',
      icon: '',
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      onlyForAdmin :false,
      onlyForDistributionTablesUser: false,
      onlyForEAuctionUser : false,
      submenu: [
        {
          path: '/transfers',
          title: 'sidebar.transfers-title',
          icon: '',
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: [],
          onlyForAdmin :false,
          onlyForDistributionTablesUser: false,
          onlyForEAuctionUser : false
        },
        {
          path: '/history',
          title: 'sidebar.history-title',
          icon: '',
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: [],
          onlyForAdmin :false,
          onlyForDistributionTablesUser: false,
          onlyForEAuctionUser : false
        }
      ]
    },
    {
      path: '/users',
      title: 'sidebar.users-title',
      icon: '',
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      onlyForAdmin :true,
      onlyForDistributionTablesUser: false,
      onlyForEAuctionUser : false
    },
    {
      path: '/distributions',
      title: 'sidebar.distributions-title',
      icon: '',
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      onlyForAdmin :false,
      onlyForDistributionTablesUser: true,
      onlyForEAuctionUser : false
    },
];
