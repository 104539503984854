import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UtilsService } from 'app/services/utils.service';
import { UserRoleDTO } from 'app/models/UserRoleDTO';

declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public userEmail: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private utilsService : UtilsService
  ) {}

  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');
    this.getLoggedInUser();
    let loggedInUser : UserRoleDTO = this.authService.getLoggedInUser();
    //for admin sidebar routes
    if (this.utilsService.isAdmin(loggedInUser)){
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    else{
      this.menuItems = ROUTES.filter(menuItem => !menuItem.onlyForAdmin);
      if (loggedInUser.hasAccessToDistributionTables && loggedInUser.hasAccessToEAuction){
        //do nothing
      } else if (loggedInUser.hasAccessToDistributionTables) {
        this.menuItems = this.menuItems.filter(menuItem => !menuItem.onlyForEAuctionUser);
      } else if (loggedInUser.hasAccessToEAuction) {
        this.menuItems = this.menuItems.filter(menuItem => !menuItem.onlyForDistributionTablesUser);
      }
    }


  }

  getLoggedInUser(){
    this.userEmail =  this.authService.getLoggedInUser()!=null?this.authService.getLoggedInUser().username:null; //check for null
  }

  logout() {
    this.authService.logout();
  }
  navigateToHome(){
    this.router.navigateByUrl("/home");
  }

}
