import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoginComponent } from './pages/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsService } from './services/utils.service';
import { GeneralService } from './services/general.service';
import { TranslationService } from './services/translation.service';
import { OrderByPipe } from './shared/orderby.pipe';
import { HomeComponent } from './pages/home/home.component';
import { ApiHeaderInterceptor } from './utils/ApiHeaderInterceptor';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AuctionDetailsComponent } from './pages/auction-details/auction-details.component';
import { HistoryComponent } from './pages/history/history/history.component';
import { TransfersComponent } from './pages/transfers/transfers/transfers.component';
import { UsersComponent } from './pages/users/users/users.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized/unauthorized.component';
import { DistributionsComponent } from './pages/distributions/distributions.component';
import { DistributionFormComponent } from './pages/distributions/distribution-form/distribution-form.component';
import { DatePipe } from '@angular/common';
import { PendingChangesGuard } from './services/can-deactivate-guard.service';
import { JudgementsComponent } from './pages/judgements/judgements.component';

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    LoginComponent,
    OrderByPipe,
    HomeComponent,
    AuctionDetailsComponent,
    HistoryComponent,
    TransfersComponent,
    UsersComponent,
    UnauthorizedComponent,
    DistributionsComponent,
    DistributionFormComponent,
    JudgementsComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule.forRoot(),
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    DatePipe,
    AuthService,
    AuthGuard,
    HttpClientModule,
    UtilsService,
    GeneralService,
    TranslationService,
    PendingChangesGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ApiHeaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
