import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from 'app/services/utils.service';

@Injectable()
export class ApiHeaderInterceptor implements HttpInterceptor {
  constructor(private utilsService: UtilsService) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var httpHeaders = httpRequest.headers;
    if (httpHeaders == null){
        httpHeaders = new HttpHeaders();
    }
    httpHeaders = httpHeaders.append(
        'aylon_api_key',
        this.utilsService.encryptedApiKey()
      );
    return next.handle(httpRequest.clone({ headers: httpHeaders }));
  }
}
