import { ClaimDTO } from "./ClaimDTO";
import { DebtorDTO } from "./DebtorDTO";

export class DistributionDTO {
  id: number;
  distributionId : string;
  uniqueCode: string;
  auctionAmount: number;
  executionExpenses: number;
  notaryExpenses: number;
  debtors: DebtorDTO[];
  claims: ClaimDTO[];
  auctioneerUserName: string;
  creationDate: Date;
  debtorNames: string;
  debtorAfms: string;
}