import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SpinnerComponent } from './spinner/spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { AuctionPartComponent } from './cells/auction-part.component';
import { TableComponent } from './table/table.component';


@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    TableComponent,
    ToggleFullscreenDirective,
    SpinnerComponent,
    TranslateModule,
    NgbModule,
    ReactiveFormsModule,
    AuctionPartComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    TableComponent,
    ToggleFullscreenDirective,
    SpinnerComponent,
    AuctionPartComponent
  ]
})
export class SharedModule {}
