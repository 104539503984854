import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { AuctionDTO } from "app/models/AuctionDTO";
import { SearchAuctionsRequestDTO } from "app/models/SearchAuctionsRequestDTO";
import { Column } from "app/models/Column";
import { GeneralService } from "app/services/general.service";
import { SwalType, UtilsService } from "app/services/utils.service";
import { AuthService } from "app/shared/auth/auth.service";
import { Constants } from "app/utils/constants.config";
import * as moment from "moment/moment";
import { Subscription } from "rxjs";
import { SearchAuctionFiltersDTO } from "app/models/SearchAuctionFiltersDTO";
import { HideUnhideAuctionRequestDTO } from "app/models/HideUnhideAuctionRequestDTO";
import { InsertAuctionToEauctionsRequestDTO } from "app/models/InsertAuctionToEauctionsRequestDTO";
import { TranslationService } from "app/services/translation.service";
import { UserRoleDTO } from "app/models/UserRoleDTO";
import { AutomaticTransferRequestDTO } from "app/models/AutomaticTransferRequestDTO";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from 'sweetalert2';

moment.locale("el_GR");
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit, OnDestroy {
  loggedInUser : UserRoleDTO = null;
  rows: AuctionDTO[];
  totalRows: number;
  selectedAuctions : AuctionDTO[];
  isLoading: boolean = true;
  isInsertLoading: boolean = false;
  isHideUnhideLoading: boolean = false;
  columns: Column[];
  limit = Constants.PAGING_LIMIT;
  currentPage: number;
  todayDate : any;
  startDate : string;
  endDate : string;
  isAutomaticTransferLoading : boolean = false;
  parentScreen = Constants.Screens.AUCTIONS_SCREEN;
  @ViewChild('afmDebtorId') afmDebtorElement:ElementRef;
  @ViewChild('uniqueCodeId') uniqueCodeElement:ElementRef;
  @ViewChild('showHiddenId') showHiddenElement:ElementRef;

  dateRangePickerLocale = {
    applyLabel : this.translationService.translate("general.apply"),
    clearLabel : this.translationService.translate("general.clearShort"),
    format: "DD/MM/YYYY"
  };
  searchAuctionsSubscription: Subscription = null;
  insertAuctionsToEauctionsSubscription: Subscription = null;
  hideUnhideAuctionSubscription: Subscription = null;
  automaticTransferSubscription: Subscription = null;
  isLoggedInUserAuctioneer : boolean = false;
  auctionWasHidden: boolean = false;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private generalService: GeneralService,
    private authService: AuthService,
    private modalService: NgbModal,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    // This line is to be able to use (onSameUrlNavigation), to reload on the same page
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.loggedInUser = this.authService.getLoggedInUser();
    if(!this.utilsService.hasAccessToEAuction(this.loggedInUser)){
      if(this.utilsService.hasAccessToDistributionTables(this.loggedInUser)){
        this.router.navigate(["/distributions"], {replaceUrl:true});
      }
      else{
        this.router.navigate(["/unauthorized"], {replaceUrl:true});
      }
    }

    this.isLoggedInUserAuctioneer = this.utilsService.isAuctioneer(this.loggedInUser);
    this.todayDate = moment();
    this.onSearchAuctions();
  }

  ngOnDestroy() {
    if (this.searchAuctionsSubscription) {
      this.searchAuctionsSubscription.unsubscribe();
    }
    if (this.insertAuctionsToEauctionsSubscription) {
      this.insertAuctionsToEauctionsSubscription.unsubscribe();
    }
    if (this.hideUnhideAuctionSubscription){
      this.hideUnhideAuctionSubscription.unsubscribe();
    }
    if (this.automaticTransferSubscription){
      this.automaticTransferSubscription.unsubscribe();
    }
  }

  onSearchAuctions() {
    this.isLoading = true;
    this.rows = new Array();
    this.totalRows = 0;
    this.selectedAuctions = new Array();
    if (this.searchAuctionsSubscription){
      this.searchAuctionsSubscription.unsubscribe();
    }

    let searchAuctionFiltersDTO : SearchAuctionFiltersDTO = {
      conductDateFrom : this.startDate!=null ? this.startDate : "",
      conductDateTo : this.endDate!=null ? this.endDate : "",
      debtorVatNumber : this.afmDebtorElement.nativeElement.value!=null ? this.afmDebtorElement.nativeElement.value : "",
      uniqueCode : this.uniqueCodeElement.nativeElement.value!=null ? this.uniqueCodeElement.nativeElement.value : "",
      showHidden : this.showHiddenElement.nativeElement.checked!=null ? this.showHiddenElement.nativeElement.checked : false,
    };

    let searchAuctionsRequestDTO : SearchAuctionsRequestDTO = {
        searchFilters : searchAuctionFiltersDTO,
        loggedInUser: this.loggedInUser
    }

    this.searchAuctionsSubscription = this.generalService
      .postRequest(
        Constants.SEARCH_AUCTIONS_URL,
        searchAuctionsRequestDTO,
        null,
        null
      )
      .subscribe(this.searchAuctionsCallback, this.searchAuctionsCallbackError);
  }

  searchAuctionsCallback = (response) => {
    if (response.success) {
      this.columns = response.resource!=null ? response.resource.columns: new Array();
      this.currentPage = 0;
      this.totalRows = response.resource!=null && response.resource.data!=null? response.resource.data.length : 0;
      this.rows = response.resource!=null && response.resource.data!=null? response.resource.data : new Array();
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isLoading = false;
  };

  searchAuctionsCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isLoading = false;
  };

  onAuctionSelect(auctions: AuctionDTO[]) {
    this.selectedAuctions = new Array();
    if (auctions!=null) {
      this.selectedAuctions = JSON.parse(JSON.stringify(auctions));
    }
  }

  onSortByDateClicked(event){
    const propertyName = event.propertyName;
    const type = event.type;
    if(propertyName == "auctionDateFormatted" && type == "desc"){
      this.rows.sort((a, b) => {
        return <any>new Date(b.auctionDate) - <any>new Date(a.auctionDate);
      });
    }
    if(propertyName == "auctionDateFormatted" && type == "asc"){
      this.rows.sort((a, b) => {
        return <any>new Date(a.auctionDate) - <any>new Date(b.auctionDate);
      });
    }
  }

  onClicked(auction: AuctionDTO) {
    if (auction!=null && auction.uniqueCode!=null && auction.uniqueCode.length>0){
      this.router.navigate(['/auctionDetails'], {queryParams: { code:  this.utilsService.encryptData(auction.uniqueCode)} });
    }
  }

  onInsertToEauctionClicked(auction: AuctionDTO) {
    this.selectedAuctions = new Array();
    if (auction!=null) {
      let obj = JSON.parse(JSON.stringify(auction));
      if (obj!=null){
        this.selectedAuctions.push(obj);
        this.insertAuctionsToEauctions();
      }
    }
  }

  insertAuctionsToEauctions(){
    if (this.selectedAuctions != null && this.selectedAuctions.length > 0) {
      if (!this.isInsertLoading) {
        this.isInsertLoading = true;
        if (this.insertAuctionsToEauctionsSubscription) {
          this.insertAuctionsToEauctionsSubscription.unsubscribe();
        }

        let insertAuctionToEauctionsRequestDTO = new InsertAuctionToEauctionsRequestDTO();
        insertAuctionToEauctionsRequestDTO.auctionCodes = new Array();
        this.selectedAuctions.forEach( (element) => {
          insertAuctionToEauctionsRequestDTO.auctionCodes.push(element.uniqueCode);
        });
        insertAuctionToEauctionsRequestDTO.userId = this.loggedInUser != null ? this.loggedInUser.userId : null;
        insertAuctionToEauctionsRequestDTO.loggedInUser = this.loggedInUser;

        this.insertAuctionsToEauctionsSubscription = this.generalService
          .postRequest(
            Constants.INSERT_AUCTIONS_TO_EAUCTIONS_URL,
            insertAuctionToEauctionsRequestDTO,
            null,
            null
          )
          .subscribe(this.insertAuctionsToEauctionsCallback, this.insertAuctionsToEauctionsCallbackError);
      }
    }
  }

  insertAuctionsToEauctionsCallback = (response) => {
    if (response.success) {
      if (this.selectedAuctions!=null && this.selectedAuctions.length==1){
        this.utilsService.showSwal(this.translationService.translate("auction-screen.auction-is-inserted-successfully"), SwalType.Success);
      }
      else{
        this.utilsService.showSwal(this.translationService.translate("auction-screen.auctions-are-inserted-successfully"), SwalType.Success);
      }
      this.selectedAuctions = new Array();
      this.onSearchAuctions();
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
      this.onSearchAuctions(); //to avoid a bug of check all box (check all-> press activate from row->does not uncheck all and cause confusion)
    }
    this.isInsertLoading = false;
  };

  insertAuctionsToEauctionsCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.onSearchAuctions(); //to avoid a bug of check all box (check all-> press activate from row->does not uncheck all and cause confusion)
    this.isInsertLoading = false;
  };

  onHideUnHideClicked(auction: AuctionDTO) {
    if (auction!=null){
      if (!this.isHideUnhideLoading) {
        // swal
        this.auctionWasHidden = auction.isHidden;
        Swal({
          title: this.translationService.translate("auction-screen.hide-confirmation-title"), //Είστε σίγουρος ότι θέλετε να προχωρήσετε σε απόκρυψη του πλειστηριασμού;
          text: this.auctionWasHidden ? this.translationService.translate("auction-screen.unhide-confirmation-message") : this.translationService.translate("auction-screen.hide-confirmation-message"),
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.translationService.translate("auction-screen.yes"),
          cancelButtonColor: '#d33',
          cancelButtonText: this.translationService.translate("auction-screen.no"),
          allowOutsideClick: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return new Promise((resolve) => {
              this.isHideUnhideLoading = true;
              if (this.hideUnhideAuctionSubscription) {
                this.hideUnhideAuctionSubscription.unsubscribe();
              }

              let hideUnhideAuctionRequestDTO : HideUnhideAuctionRequestDTO = {
                auctionCode : auction.uniqueCode,
                loggedInUser : this.loggedInUser,
                hide: !auction.isHidden
              }

              this.hideUnhideAuctionSubscription = this.generalService
                .postRequest(Constants.HIDE_UNHIDE_AUCTION_URL, hideUnhideAuctionRequestDTO, null, null)
                .subscribe(this.hideUnhideCallback, this.hideUnhideCallbackError);
            });
          }
        });
      }
    }
  }

  hideUnhideCallback = (response) => {
    if (response.success) {
      this.utilsService.showSwal(this.auctionWasHidden ? this.translationService.translate("auction-screen.unhide-auction-success") : this.translationService.translate("auction-screen.hide-auction-success"), SwalType.Success).then(() => { this.onSearchAuctions(); });
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isHideUnhideLoading = false;
  };

  hideUnhideCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isHideUnhideLoading = false;
  };

  onStartDateChanged(event) {
    if (event) {
      this.startDate = event.startDate!=null?event.startDate.format("YYYY-MM-DD"):null;
    }
  }

  onEndDateChanged(event){
    if(event){
      this.endDate = event.endDate!=null?event.endDate.format("YYYY-MM-DD"):null;
    }
  }

  switchClicked(isChecked, content){
    if (this.loggedInUser!=null){
      this.loggedInUser.automaticTransferEnabled = isChecked;
    }

    this.modalService.open(content,{size: <any>"md", backdrop  : 'static', keyboard  : false, centered: true})
      .result.then(
        (result) => {},
        (reason) => {}
        );
  }

  onSwitchClickedConfirm() {
    this.changeAutomaticTransfer();
  }

  onSwitchClickedDeclined(){
    setTimeout(() => {
      this.loggedInUser = this.authService.getLoggedInUser();
      this.modalService.dismissAll();    
    }, 500);  
  }

  changeAutomaticTransfer() {
    if (!this.isAutomaticTransferLoading) {
      this.isAutomaticTransferLoading = true;
      if (this.automaticTransferSubscription) {
        this.automaticTransferSubscription.unsubscribe();
      }

      let automaticTransferRequestDTO: AutomaticTransferRequestDTO = {
        loggedInUser: this.loggedInUser,
        activate: this.loggedInUser != null ? this.loggedInUser.automaticTransferEnabled : false
      }

      this.automaticTransferSubscription = this.generalService
        .postRequest(
          Constants.ACTIVATE_DEACTIVATE_AUTOMATIC_TRANSFER_URL,
          automaticTransferRequestDTO,
          null,
          null
        )
        .subscribe(this.automaticTransferCallback, this.automaticTransferCallbackError);
    }
    else{
        setTimeout(() => {
          this.loggedInUser = this.authService.getLoggedInUser();
          this.modalService.dismissAll();
        }, 500);      
    }
  }

  automaticTransferCallback = (response) => {
    if (response.success) {
      this.authService.saveLoggedInUser(this.loggedInUser);
      this.utilsService.showSwal(this.translationService.translate("auction-screen.automatic-transfer-modified-successfully"), SwalType.Success);
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    setTimeout(() => {
      this.loggedInUser = this.authService.getLoggedInUser();
      this.isAutomaticTransferLoading = false;
      this.modalService.dismissAll();
    }, 500);
  };

  automaticTransferCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    setTimeout(() => {
      this.loggedInUser = this.authService.getLoggedInUser();
      this.isAutomaticTransferLoading = false;
      this.modalService.dismissAll();
    }, 500);
  };

}
