import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'app/utils/constants.config';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit, OnDestroy {
  parentScreen = Constants.Screens.UNAUTHORIZED_SCREEN;
  constructor(private router: Router) { }

  ngOnInit() {
    window.scroll(0, 0);
    // This line is to be able to use (onSameUrlNavigation), to reload on the same page
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnDestroy() {
  }
}
