import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Constants } from 'app/utils/constants.config';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UtilsService } from 'app/services/utils.service';
import { CustomHttpParamEncoder } from '../../utils/CustomHttpParamEncoder';
import { LoginResponse } from '../../models/LoginResponse';
import { UserRoleDTO } from '../../models/UserRoleDTO';
import { TranslationService } from '../../services/translation.service';

@Injectable()
export class AuthService {

  constructor(private httpClient: HttpClient, private router: Router, private utilsService: UtilsService, private translationService: TranslationService) {
  }

  handleErrorResponse(error: HttpErrorResponse) {
    if (error != null && error.status === 401) {
      this.logout();
    }
  }

  logout() {
    this.removeSavedValues();
    this.router.navigate(['/login'], {});
  }

  removeSavedValues() {
    sessionStorage.clear();
  }

  generateAuthorizationHeaders() {
    let httpHeaders = new HttpHeaders();
    if (this.isAuthenticated()){
      // if (this.getToken()) {
      //   httpHeaders = httpHeaders.append(
      //     'Authorization',
      //     'Bearer ' + this.getToken()
      //   );
      // }
      return httpHeaders;
    }
    else{
      this.logout();
      return httpHeaders;
    }
  }

  isAuthenticated() {
    let loggedInUser = this.getLoggedInUser();
    return loggedInUser != null && loggedInUser.username!=null && loggedInUser.username.length>0;
  }

  signinUser(username: string, password: string) {
    const url = this.utilsService.getBaseUrl() + Constants.LOGIN_URL;
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    httpHeaders = httpHeaders.append(
      'Accept-Language',
      this.translationService.getApiLanguage()
    );
    let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    httpParams = httpParams.append('username', username);
    httpParams = httpParams.append('password', password);
    return this.httpClient
      .post<LoginResponse<UserRoleDTO>>(url, httpParams.toString(), {
        headers: httpHeaders
      })
      .pipe(
        map(this.mapResponse)
      );
  }

  mapResponse = (response: LoginResponse<UserRoleDTO>) => {
    return response;
  }

  saveLoggedInUser(userRole: UserRoleDTO) {  // save the logged in user
    sessionStorage.setItem(Constants.SessionStorage.LOGGED_IN_USER_KEY, this.utilsService.encryptData(JSON.stringify(userRole)));
  }

  getLoggedInUser(): UserRoleDTO {  // get the logged in user
    if (sessionStorage != null && sessionStorage.getItem(Constants.SessionStorage.LOGGED_IN_USER_KEY) != null) {
      const user = JSON.parse(this.utilsService.decryptData(sessionStorage.getItem(Constants.SessionStorage.LOGGED_IN_USER_KEY)));
      return user;
    } else {
      return null;
    }
  }
}
