import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Column } from 'app/models/Column';
import { DistributionDTO } from 'app/models/DistributionDTO';
import { UserRoleDTO } from 'app/models/UserRoleDTO';
import { GeneralService } from 'app/services/general.service';
import { TranslationService } from 'app/services/translation.service';
import { SwalType, UtilsService } from 'app/services/utils.service';
import { AuthService } from 'app/shared/auth/auth.service';
import { Constants } from 'app/utils/constants.config';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-distributions',
  templateUrl: './distributions.component.html',
  styleUrls: ['./distributions.component.css']
})
export class DistributionsComponent implements OnInit {

  loggedInUser: UserRoleDTO = new UserRoleDTO();

  searchDistributionsSubscription: Subscription = null;

  distributionsLoading: boolean = false;
  rows: DistributionDTO[];
  totalRows: number;
  columns: Column[];
  currentPage: number;
  afm : string;
  uniqueNumber: string;
  name: string;

  @ViewChild('nameDebtorId') nameDebtorElement:ElementRef;
  @ViewChild('afmDebtorId') afmDebtorElement:ElementRef;
  @ViewChild('uniqueCodeId') uniqueCodeElement:ElementRef;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private authService: AuthService,
    private generalService: GeneralService,
    private translationService: TranslationService,
  ) { }

  ngOnInit() {
    this.loggedInUser = this.authService.getLoggedInUser();
    if(!this.utilsService.hasAccessToDistributionTables(this.loggedInUser)){
      if(this.utilsService.hasAccessToEAuction(this.loggedInUser)){
        this.router.navigate(["/home"], {replaceUrl:true});
      }
      else{
        this.router.navigate(["/unauthorized"], {replaceUrl:true});
      }
    }
    this.onSearchDistributions();
  }

  onAddDistribution() {
    this.router.navigate(['/distributions/distribution']);
  }

  onSearchDistributions(){
    this.distributionsLoading = true;
    this.rows = new Array();
    this.totalRows = 0;
    if(this.searchDistributionsSubscription){
      this.searchDistributionsSubscription.unsubscribe();
    }

    this.name = this.nameDebtorElement.nativeElement.value != null ? this.nameDebtorElement.nativeElement.value : "";
    this.afm = this.afmDebtorElement.nativeElement.value != null ? this.afmDebtorElement.nativeElement.value : "";
    this.uniqueNumber = this.uniqueCodeElement.nativeElement.value != null ? this.uniqueCodeElement.nativeElement.value : "";
    this.searchDistributionsSubscription = this.generalService
    .postRequest(
      Constants.SEARCH_DISTRIBUTIONS_URL,
      this.loggedInUser,
      [this.afm,this.uniqueNumber,this.name],
      ["afm","uniqueCode","name"]
    ).subscribe(this.onSearchDistributionsCallback,this.onSearchDistributionsCallbackError);

  }

  onSearchDistributionsCallback = (response) =>{
    if (response.success) {
      this.columns = response.resource!=null ? response.resource.columns: new Array();
      this.currentPage = 0;
      this.totalRows = response.resource!=null && response.resource.data!=null? response.resource.data.length : 0;
      this.rows = response.resource!=null && response.resource.data!=null? response.resource.data : new Array();
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.distributionsLoading = false;
  }
  
  onSearchDistributionsCallbackError = (error : HttpErrorResponse) =>{
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
  }

  onEditDistributionClicked(distribution : DistributionDTO){
    this.router.navigate(['/distributions/distribution'], {queryParams: { id:  this.utilsService.encryptData(distribution.id)} });
  }

  onSortDistributionsClicked(event){
    if (event.propertyName == 'uniqueCode'){
      if (event.type == 'asc'){
        this.rows.sort((a,b) => a.uniqueCode.localeCompare(b.uniqueCode));
      } else {
        this.rows.sort((a,b) => b.uniqueCode.localeCompare(a.uniqueCode));
      }
    }
  }

  onDeleteDistributionClicked(distribution : DistributionDTO){
    Swal({
      title: this.translationService.translate("distribution-screen.deleteSwalTitle"),
      text: this.translationService.translate("distribution-screen.irreversible"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: this.translationService.translate("distribution-screen.delete"),
      cancelButtonColor: '#d33',
      cancelButtonText: this.translationService.translate("distribution-screen.cancel"),
      allowOutsideClick: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise((resolve) => {
          this.generalService.postRequest(Constants.DELETE_DISTRIBUTION_TABLE_URL, this.loggedInUser, [distribution.id],['distributionId']).subscribe(this.deleteDistributionTableCallback, this.deleteDistributionTableCallbackError);
        });
      }
    });
  }

  deleteDistributionTableCallback = (response) =>{
    if (response.success) {
      this.utilsService.showSwal(this.translationService.translate("distribution-screen.deleteDistributionSuccess"), SwalType.Success).then(() => { this.onSearchDistributions(); });
    } else {
      this.utilsService.showSwal(response.message, SwalType.Error);
    }
  }

  deleteDistributionTableCallbackError = (error: HttpErrorResponse) =>{
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
  }

}


