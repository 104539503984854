import { ClaimDTO } from "./ClaimDTO";

export class DistributionRequestDTO {
  debtorName: string;
  debtorAfm: string;
  debtorPercentage: number;
  uniqueCode: string;
  auctionAmount: number;
  executionExpenses: number;
  notaryExpenses: number;
  superPrivilegeTitle: string;
  superPrivilegeAmount: number;
  finalExpensesAmount: number;
  finalDistributionAmount: number;
  totalAmountFor975: number;
  totalAmountFor976: number;
  totalAmountFor976_3: number;
  totalAmountForManuscriptLenders: number;
  claims975: ClaimDTO[];
  claims976: ClaimDTO[];
  claimsManuscriptLenders: ClaimDTO[];
  auctioneerUserName: string;
}
