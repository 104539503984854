import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Column } from 'app/models/Column';
import { HideUnhideJudgementRequestDTO } from 'app/models/HideUnhideJudgementRequestDTO';
import { JudgementDTO } from 'app/models/JudgementDTO';
import { SearchAuctionFiltersDTO } from 'app/models/SearchAuctionFiltersDTO';
import { SearchAuctionsRequestDTO } from 'app/models/SearchAuctionsRequestDTO';
import { UserRoleDTO } from 'app/models/UserRoleDTO';
import { GeneralService } from 'app/services/general.service';
import { TranslationService } from 'app/services/translation.service';
import { SwalType, UtilsService } from 'app/services/utils.service';
import { AuthService } from 'app/shared/auth/auth.service';
import { Constants } from 'app/utils/constants.config';
import * as moment from "moment/moment";
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-judgements',
  templateUrl: './judgements.component.html',
  styleUrls: ['./judgements.component.css']
})
export class JudgementsComponent implements OnInit {
  
  loggedInUser : UserRoleDTO = null;
  rows: JudgementDTO[];
  totalRows: number;
  isLoading: boolean = true;
  isHideUnhideLoading: boolean = false;
  judgementWasHidden: boolean = false;
  isDownloadCourtReportLoading: boolean = false;
  todayDate : any;
  startDate : string;
  endDate : string;
  columns: Column[];
  currentPage: number;
  @ViewChild('afmDebtorId') afmDebtorElement:ElementRef;
  @ViewChild('uniqueCodeId') uniqueCodeElement:ElementRef;
  @ViewChild('showHiddenId') showHiddenElement:ElementRef;
  
  dateRangePickerLocale = {
    applyLabel : this.translationService.translate("general.apply"),
    clearLabel : this.translationService.translate("general.clearShort"),
    format: "DD/MM/YYYY"
  };

  hideUnhideJudgementSubscription: Subscription = null;


  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private generalService: GeneralService,
    private authService: AuthService,
    private translationService: TranslationService
    ) { }

  ngOnInit() {
    window.scroll(0, 0);
    // This line is to be able to use (onSameUrlNavigation), to reload on the same page
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.loggedInUser = this.authService.getLoggedInUser();
    if(!this.utilsService.hasAccessToDistributionTables(this.loggedInUser)){
      if(this.utilsService.hasAccessToEAuction(this.loggedInUser)){
        this.router.navigate(["/home"], {replaceUrl:true});
      }
      else{
        this.router.navigate(["/unauthorized"], {replaceUrl:true});
      }
    }

    this.todayDate = moment();
    this.onSearchJudgements();
  }

  ngOnDestroy(){
    if(this.hideUnhideJudgementSubscription){
      this.hideUnhideJudgementSubscription.unsubscribe();
    }
  }
  

  onSearchJudgements() {
    this.isLoading = true;
    this.rows = new Array();
    this.totalRows = 0;

    let searchJudgementsFiltersDTO : SearchAuctionFiltersDTO = {
      conductDateFrom : this.startDate!=null ? this.startDate : "",
      conductDateTo : this.endDate!=null ? this.endDate : "",
      debtorVatNumber : this.afmDebtorElement.nativeElement.value!=null ? this.afmDebtorElement.nativeElement.value : "",
      uniqueCode : this.uniqueCodeElement.nativeElement.value!=null ? this.uniqueCodeElement.nativeElement.value : "",
      showHidden : this.showHiddenElement.nativeElement.checked!=null ? this.showHiddenElement.nativeElement.checked : false,
    };

    let searchJudgementsRequestDTO : SearchAuctionsRequestDTO = {
        searchFilters : searchJudgementsFiltersDTO,
        loggedInUser: this.loggedInUser
    }

    this.generalService.postRequest(Constants.SEARCH_JUDGEMENTS_URL, searchJudgementsRequestDTO, null, null).subscribe(this.searchJudgementsCallback, this.searchJudgementsCallbackError);
  }

  searchJudgementsCallback = (response) => {
    if (response.success) {
      this.columns = response.resource!=null ? response.resource.columns: new Array();  
      this.currentPage = 0;
      this.totalRows = response.resource!=null && response.resource.data!=null? response.resource.data.length : 0;
      this.rows = response.resource!=null && response.resource.data!=null? response.resource.data : new Array();
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isLoading = false;
  };

  searchJudgementsCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isLoading = false;
  };

  onHideUnHideClicked(judgement: JudgementDTO) {
    if (judgement!=null){
      if (!this.isHideUnhideLoading) {
        // swal
        this.judgementWasHidden = judgement.isHidden;
        Swal({
          title: this.translationService.translate("judgements-screen.hide-confirmation-title"),
          text: this.judgementWasHidden ? this.translationService.translate("judgements-screen.unhide-confirmation-message") : this.translationService.translate("judgements-screen.hide-confirmation-message"),
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.translationService.translate("judgements-screen.yes"),
          cancelButtonColor: '#d33',
          cancelButtonText: this.translationService.translate("judgements-screen.no"),
          allowOutsideClick: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return new Promise((resolve) => {
              this.isHideUnhideLoading = true;
              if (this.hideUnhideJudgementSubscription) {
                this.hideUnhideJudgementSubscription.unsubscribe();
              }

              let hideUnhideJudgementRequestDTO : HideUnhideJudgementRequestDTO = {
                uniqueCode : judgement.uniqueCode,
                loggedInUser : this.loggedInUser,
                hide: !judgement.isHidden
              }

              this.hideUnhideJudgementSubscription = this.generalService
                .postRequest(Constants.HIDE_UNHIDE_JUDGEMENT_URL, hideUnhideJudgementRequestDTO, null, null)
                .subscribe(this.hideUnhideCallback, this.hideUnhideCallbackError);
            });
          }
        });
      }
    }
  }



  hideUnhideCallback = (response) => {
    if (response.success) {
      this.utilsService.showSwal(this.judgementWasHidden ? this.translationService.translate("judgements-screen.unhide-judgement-success") : this.translationService.translate("judgements-screen.hide-judgement-success"), SwalType.Success).then(() => { this.onSearchJudgements(); });
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isHideUnhideLoading = false;
  };

  hideUnhideCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isHideUnhideLoading = false;
  };


  onStartDateChanged(event) {
    if (event) {
      this.startDate = event.startDate!=null?event.startDate.format("YYYY-MM-DD"):null;
    }
  }

  onEndDateChanged(event){
    if(event){
      this.endDate = event.endDate!=null?event.endDate.format("YYYY-MM-DD"):null;
    }
  }

  downloadCourtReport(judgement: JudgementDTO){
    this.isDownloadCourtReportLoading = true;
    if (judgement.fileUrl != null && judgement.fileUrl.length > 0) {
      var judgementFile = judgement.fileUrl.replace("[","").replace("]","").replace(/['"]+/g, ''); //TODO once we have a list of file handle appropriately
      window.open(judgementFile, "_blank");
    }
    this.isDownloadCourtReportLoading = false;
  }

}
