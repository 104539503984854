import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Column } from 'app/models/Column';
import { TranslationService } from 'app/services/translation.service';
import { Constants } from 'app/utils/constants.config';
import { Utils } from 'app/utils/utils';

//todo try to disable checkboxes if (isInsertLoading || isActivateUserLoading || isDeactivateUserLoading)
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() isEditEnabled: boolean;
  @Input() showDistributionsControls: boolean;
  @Input() limit: number;
  @Input() rows: any[];
  @Input() columns: any[];
  @Input() selectionType: string;
  @Input() title: string;
  // @Input() maxHeight = '715px';
  @Input() maxHeight = '';

  @Input() isSearchEnabled: boolean;
  @Input() isCustomActionsEnabled: boolean;
  @Input() isInsertLoading: boolean;
  @Input() isHideUnhideLoading: boolean;

  @Input() isCustomUserActionsEnabled: boolean;
  @Input() isActivateUserLoading: boolean;
  @Input() isDeactivateUserLoading: boolean;
  @Input() isJudgementFilesEnabled: boolean;
  @Input() isJudgementActionsEnabled: boolean;

  @Input() externalPagingEnabled?: boolean;
  @Input() externalSortingEnabled?: boolean;
  @Input() totalItems?: number;
  @Input() pageNumber?: number;

  @Output() searchListener = new EventEmitter<string>();
  @Output() selectListener = new EventEmitter<any>();
  @Output() pageChangedListener = new EventEmitter<number>();
  @Output() sortListener = new EventEmitter<any>();
  @Output() addListener = new EventEmitter<any>();

  @Output() modifyUserListener = new EventEmitter<any>();
  @Output() deleteUserListener = new EventEmitter<any>();
  @Output() activateUserListener = new EventEmitter<any>();


  @Output() onClickListener = new EventEmitter<any>();
  @Output() onInsertToEauctionListener = new EventEmitter<any>();
  @Output() onHideUnHideListener = new EventEmitter<any>();
  
  @Output() onActivateUserListener = new EventEmitter<any>();
  @Output() onDeactivateUserListener = new EventEmitter<any>();

  @Output() ClickedDistributionListener = new EventEmitter<any>();
  @Output() EditDistributionListener = new EventEmitter<any>();
  @Output() DeleteDistributionListener = new EventEmitter<any>();

  @Output() onDownloadJudgementListener = new EventEmitter<any>();
  @Output() onHideUnHideJudgementListener = new EventEmitter<any>();

  tempData: any[];
  showAdd = false;
  messages = {
    emptyMessage: `
    <div class="row">
    <div class="col-12">
      <div>
        `+this.translationService.translate("general.no-data-available")+`
      </div>
    </div>
  </div>
    `
  }
  tableStyle = {};
  selected = [];
  EMPTY_STRING_VALUE : string  = Constants.EMPTY_STRING_VALUE;

  constructor(private translationService : TranslationService) {}

  onAddItem() {
    this.addListener.emit(null);
  }

  onPageChanged(event) {
    const page = event.offset + 1;
    this.pageChangedListener.next(page);
  }

  onSortClicked(event) {
    const propertyName = event.column.prop;
    const type = event.newValue;
    this.sortListener.next({ propertyName, type });
  }

  onSearch(event) {
    this.searchListener.emit(event.target.value);
  }

  
  onSelect({ selected }) {

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (this.selectListener){
      this.selectListener.emit(this.selected);
    }
  }

  onActivate(event) {
    if(event.type == 'click' && ((this.isCustomUserActionsEnabled && event.cellIndex != 6) || (!this.isCustomUserActionsEnabled && event.cellIndex!=5))) {
      if (this.onClickListener){
        this.onClickListener.emit(event.row);
      }
    }
  }

  ngOnInit() {
    this.showAdd = this.addListener.observers.length > 0;
    this.tableStyle = {
      'overflow-y': 'auto',
      'max-height': this.maxHeight,
    }
  }

  onEditUserClicked(user) {
    this.modifyUserListener.emit(user);
  }

  onDeleteUserClicked(user) {
    this.deleteUserListener.emit(user);
  }

  onActivateUserClicked(user) {
    this.activateUserListener.emit(user);
  }

  getColor(column: Column, row) {
    if (column.shouldColor) {
      if (!this.isCustomUserActionsEnabled) {
        return row['color'];
      }
      else {
        return row != null && row.isActive ? "#98C42E" : "#c2352e";
      }
    }
  }

  insertToEauctionClicked(item, event) {
    event.stopPropagation();
    if (!this.isInsertLoading){
      this.selected.splice(0, this.selected.length);
      this.selected.push(item);
      if (this.onInsertToEauctionListener){
        this.onInsertToEauctionListener.emit(item);
      }
    }
  }

  hideUnHideClicked(item, event) {
    event.stopPropagation();
    if (!this.isHideUnhideLoading) {
      if (this.onHideUnHideListener) {
        this.onHideUnHideListener.emit(item);
      }
    }
  }

  onClickedDistributionListener(item){
    if(this.ClickedDistributionListener){
      this.ClickedDistributionListener.emit(item);
    }
  }

  onEditDistributionClicked(item, event){
    event.stopPropagation();
    if(this.EditDistributionListener){
      this.EditDistributionListener.emit(item);
    }
  }

  onDeleteDistributionClicked(item, event){
    event.stopPropagation();
    if(this.DeleteDistributionListener){
      this.DeleteDistributionListener.emit(item);
    }
  }

  activateUserClicked(item, event) {
    event.stopPropagation();
    if (!this.isActivateUserLoading && !this.isDeactivateUserLoading){
      this.selected.splice(0, this.selected.length);
      this.selected.push(item);
      if (this.onActivateUserListener){
        this.onActivateUserListener.emit(item);
      }
    }
  }

  deactivateUserClicked(item, event) {
    event.stopPropagation();
    if (!this.isDeactivateUserLoading && !this.isActivateUserLoading){
      this.selected.splice(0, this.selected.length);
      this.selected.push(item);
      if (this.onDeactivateUserListener){
        this.onDeactivateUserListener.emit(item);
      }
    }
  }

  downloadJudgementClicked(item, event){
    event.stopPropagation();
    if (!this.isHideUnhideLoading) {
      if (this.onDownloadJudgementListener) {
        this.onDownloadJudgementListener.emit(item);
      }
    }
  }

  hideUnHideJudgementClicked(item, event){
    event.stopPropagation();
    if (!this.isHideUnhideLoading) {
      if (this.onHideUnHideJudgementListener) {
        this.onHideUnHideJudgementListener.emit(item);
      }
    }
  }

  formatValue(value){
    return Utils.formatValue(value);
  }

  getSimplifiedName(docName: string) : string{

    //will match anything of type report(*)
    const pattern: RegExp = /report\d+\.pdf/;

    // Use regular expression to find the desired substring
    const match: RegExpMatchArray | null = docName.match(pattern);
    
    if (match !== null) {
      const desiredSubstring: string = match[0];
      return desiredSubstring;
    } else {
      return docName;
    }
  }

}
