import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/shared/auth/auth.service';
import { CustomHttpParamEncoder } from '../utils/CustomHttpParamEncoder';
import { TranslationService } from './translation.service';
import { UtilsService } from './utils.service';
import * as FileSaver from 'file-saver';

@Injectable()
export class GeneralService {

  constructor(private httpClient: HttpClient, private authService: AuthService, private utilsService: UtilsService, private translationService : TranslationService) { }

  getRequest(urlSecondPart: string, params: any[], paramNames: string[]) {
    const url = this.utilsService.getBaseUrl() + urlSecondPart;
    let httpHeaders = this.authService.generateAuthorizationHeaders();
    httpHeaders = httpHeaders.append(
      'Accept-Language',
      this.translationService.getApiLanguage()
    );
    let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    if (params != null && params.length > 0) {
      for (let i = 0; i<params.length; i++) {
        if (params[i]) {
          httpParams = httpParams.append(paramNames[i], params[i] + '');
        }
      }
    }
    return this.httpClient.get(url, { headers: httpHeaders, params: httpParams });
  }

  postRequest(urlSecondPart: string, model, params: any[], paramNames: string[]) {
    const url = this.utilsService.getBaseUrl() + urlSecondPart;
    let httpHeaders = this.authService.generateAuthorizationHeaders();
    httpHeaders = httpHeaders.append(
      'Accept-Language',
      this.translationService.getApiLanguage()
    );
    let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    if (params != null && params.length > 0) {
      for (let i = 0; i<params.length; i++) {
        if (params[i]) {
          httpParams = httpParams.append(paramNames[i], params[i] + '');
        }
      }
    }
    return this.httpClient.post(url, model, { headers: httpHeaders, params: httpParams });
  }

  exportDistribution(urlSecondPart: string, model, params: any[], paramNames: string[]){
    const url = this.utilsService.getBaseUrl() + urlSecondPart;
    let httpHeaders = this.authService.generateAuthorizationHeaders();
    httpHeaders = httpHeaders.append(
      'Accept-Language',
      this.translationService.getApiLanguage()
    );
    let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    if (params != null && params.length > 0) {
      for (let i = 0; i<params.length; i++) {
        if (params[i]) {
          httpParams = httpParams.append(paramNames[i], params[i] + '');
        }
      }
    }
    return this.httpClient.post(url, model, { responseType: 'arraybuffer', headers: httpHeaders, params: httpParams });
  }

  downloadExcelFile(data: any, type: string, fileName: string) {
    const blob = new Blob([data], { type: type });
    const filename = fileName + '.xlsx';
    FileSaver.saveAs(blob, filename);
  }

  downloadWordFile(data: any, type: string, fileName: string) {
    const blob = new Blob([data], { type: type });
    const filename = fileName + '.docx';
    FileSaver.saveAs(blob, filename);
  }

}
