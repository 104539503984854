import { ClaimDTO } from "./ClaimDTO";

export class CategoryTable{
  isExpanded: boolean;
  debtorName: string;
  debtorAfm: string;
  claims: ClaimDTO[];
  isAddClaim: boolean;
  isEditClaim: boolean;
  selectedClaim: ClaimDTO;
  is975: boolean;
  is976: boolean;
  isManuscriptLenders: boolean;
}