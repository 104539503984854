import { DistributionDTO } from "./DistributionDTO";

export class DebtorDTO{
    debtorId: number;
    fullName: string;
    afm: string;
    percentage: number;
    percentageStr: string;
    distributionId: number;
    distirbution: DistributionDTO;
}