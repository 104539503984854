import { Routes } from '@angular/router';
import { AuctionDetailsComponent } from 'app/pages/auction-details/auction-details.component';
import { DistributionFormComponent } from 'app/pages/distributions/distribution-form/distribution-form.component';
import { DistributionsComponent } from 'app/pages/distributions/distributions.component';
import { HistoryComponent } from 'app/pages/history/history/history.component';
import { HomeComponent } from 'app/pages/home/home.component';
import { JudgementsComponent } from 'app/pages/judgements/judgements.component';
import { TransfersComponent } from 'app/pages/transfers/transfers/transfers.component';
import { UnauthorizedComponent } from 'app/pages/unauthorized/unauthorized/unauthorized.component';
import { UsersComponent } from 'app/pages/users/users/users.component';
import { PendingChangesGuard } from 'app/services/can-deactivate-guard.service';

// Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'judgements',
        component: JudgementsComponent
    },
    {
        path: 'auctionDetails',
        component: AuctionDetailsComponent
    },
    {
        path: 'history',
        component: HistoryComponent
    },
    {
        path: 'transfers',
        component: TransfersComponent
    },
    {
        path: 'users',
        component: UsersComponent
    },
    {
        path: 'distributions',
        component: DistributionsComponent
    },
    {
        path: 'distributions/distribution',
        component: DistributionFormComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    { path: '**', redirectTo: 'home', pathMatch: 'full' }
];
