import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuctionPartDTO } from 'app/models/AuctionPartDTO';
import { TranslationService } from 'app/services/translation.service';
import { Constants } from 'app/utils/constants.config';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'auction-part',
  templateUrl: './auction-part.component.html',
  styleUrls: ['./auction-part.component.css']
})
export class AuctionPartComponent implements OnInit {
  @Input() part: AuctionPartDTO;
  @Input() totalParts: number;

  @Output() onClickListener = new EventEmitter<any>();
  @Output() onSelectListener = new EventEmitter<any>();
  @Output() onHideUnHideListener = new EventEmitter<any>();
  onInsertAuctionToEauctionLoading : boolean = false;
  isHideUnhideLoading : boolean = false;
  EMPTY_STRING_VALUE : string  = Constants.EMPTY_STRING_VALUE;

  constructor(private translationService : TranslationService) {}

  ngOnInit() {
  }

  formatValue(value){
    return Utils.formatValue(value);
  }
}
