import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';
import { UserRoleDTO } from 'app/models/UserRoleDTO';
import { Constants } from 'app/utils/constants.config';

@Injectable()
export class UtilsService {
  constructor() {}

  isAdmin(loggedInUser:UserRoleDTO):boolean{
    if (loggedInUser!=null && loggedInUser.roleName!=null && loggedInUser.roleName.toLowerCase()==Constants.Roles.ADMIN.toLowerCase()){
      return true;
    }
    return false;
  }

  isAuctioneer(loggedInUser:UserRoleDTO):boolean{
    if (loggedInUser!=null && loggedInUser.roleName!=null && loggedInUser.roleName.toLowerCase()==Constants.Roles.AUCTIONEER.toLowerCase()){
      return true;
    }
    return false;
  }

  hasAccessToEAuction(loggedInUser:UserRoleDTO):boolean{
    if(loggedInUser != null && loggedInUser.hasAccessToEAuction){
      return true;
    }
    return false;
  }

  hasAccessToDistributionTables(loggedInUser:UserRoleDTO):boolean{
    if(loggedInUser != null && loggedInUser.hasAccessToDistributionTables){
      return true;
    }
    return false;
  }

  getBaseUrl():string{
    var host = window.location.host;
    var protocol = window.location.protocol;
    var baseUrl = protocol+'//'+host;

    return baseUrl;

  }

  showSwal(message: string, messageType: SwalType) {
    if (messageType === SwalType.Error) {
      return Swal({
        title: "Σφάλμα",
        text: message,
        type: 'error',
        confirmButtonColor: '#438BC9'
      });
    } else if (messageType === SwalType.Warning) {
      return Swal({
        title: "Προσοχή",
        text: message,
        type: 'warning',
        confirmButtonColor: '#438BC9'
      });
    } else {
      return Swal({
        title: "Επιτυχία",
        text: message,
        type: 'success',
        timer: 1800,
        showConfirmButton: false 
      });
    }
  }

  encryptedApiKey(): string {
    var text = CryptoJS.enc.Utf8.parse(Date.now().toString());
    var key = CryptoJS.enc.Utf8.parse(environment.encryptionKey);
    var IV = CryptoJS.enc.Utf8.parse(environment.ivKey);
    var encryptedText = CryptoJS.AES.encrypt(text, key, { keySize: 128 / 8, iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encryptedText;
  }


  encryptData(data): string {
    var text = CryptoJS.enc.Utf8.parse(data);
    var key = CryptoJS.enc.Utf8.parse(environment.encryptionKey);
    var IV = CryptoJS.enc.Utf8.parse(environment.ivKey);
    var encryptedText = CryptoJS.AES.encrypt(text, key, { keySize: 128 / 8, iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encryptedText;
  }

  decryptData(data): string {
    var key = CryptoJS.enc.Utf8.parse(environment.encryptionKey);
    var IV = CryptoJS.enc.Utf8.parse(environment.ivKey);
    var decryptedtext = CryptoJS.AES.decrypt(data, key, { keySize: 128 / 8, iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return decryptedtext.toString(CryptoJS.enc.Utf8);
  }



}

export enum SwalType {
  Success = 1,
  Error,
  Warning
}
