export class UserRoleDTO {
  username: string;
  userId: string;
  roleName: string;
  automaticTransferEnabled: boolean;
  vatNumber: string;
  serviceType: string;
  hasAccessToEAuction: boolean;
  hasAccessToDistributionTables : boolean;
}
