import { DebtorDTO } from "./DebtorDTO";
import { DistributionDTO } from "./DistributionDTO";

export class ClaimDTO {
  id: number;
  category: string;
  index: number;
  subCategory: string;
  subCategoryStr: string;
  subSubCategory: string;
  subSubCategoryStr: string;
  title: string;
  amount: number;
  amountStr: string;
  mortgageAmount?: number;
  mortgageAmountStr?: string;
  mortgageDate?: Date;
  mortgageDateStr?: string;

  debtorId: number;
  debtor: DebtorDTO;
  distributorId: number;
  distribution: DistributionDTO;
}
