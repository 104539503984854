import { ClaimDTO } from "./ClaimDTO";

export class AmountDistributionDTO {
  debtorName: string;
  amountFor975: number;
  amountIncrementFor975: AmountIncrementDTO;
  category975Breakdown: CategoryAmountCountDistributionDTO[];
  amountFor976: number;
  amountIncrementFor976: AmountIncrementDTO;
  category976Breakdown: CategoryAmountCountDistributionDTO[];
  amountFor976_3: number;
  amountIncrementFor976_3: AmountIncrementDTO;
  amountForManuscriptLenders: number;
  amountIncrementForManuscriptLenders: AmountIncrementDTO;
  manuscriptLendersBreakdown: CategoryAmountCountDistributionDTO[];
  surplus: number;
  isTotalAmount: boolean;
  categoryDistributions: CategoryDistributionDTO[];
  categoryDistributionWrappers: CategoryDistributionWrapper[];
}

export class AmountIncrementDTO{ 
  amountFor: number;
  categoryAmounts: CategoryAmountIncrementDTO[];
}

export class CategoryAmountIncrementDTO{
  fromCategory: string;
  amount: number;
}

export class CategoryAmountCountDistributionDTO {
  category: string;
  subCategory: string;
  categoryName: string;
  claims: ClaimDTO[];
  claimAmount: number;
  distributionAmount: number;
  amountIncrement: AmountIncrementDTO;
}

export class CategoryDistributionDTO {
  title: string;
  claimDistributions: ClaimDTO[];
  percentage: number;

  public constructor(init?:Partial<CategoryDistributionDTO>) {
    Object.assign(this, init);
  }
}

export class CategoryDistributionWrapper {
  title: string;
  amount: number;
  isParent: boolean;
  parentTitle: string;
}
