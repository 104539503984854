import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { Router } from "@angular/router";
import { Column } from "app/models/Column";
import { GeneralService } from "app/services/general.service";
import { SwalType, UtilsService } from "app/services/utils.service";
import { AuthService } from "app/shared/auth/auth.service";
import { Constants } from "app/utils/constants.config";
import { Subscription } from "rxjs";
import { TranslationService } from "app/services/translation.service";
import { UserDTO } from "app/models/UserDTO";
import { SearchUsersRequestDTO } from "app/models/SearchUsersRequestDTO";
import { ActivateUsersRequestDTO } from "app/models/ActivateUsersRequestDTO";
import { DeactivateUsersRequestDTO } from "app/models/DeactivateUsersRequestDTO";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"]
})
export class UsersComponent implements OnInit, OnDestroy {
  rows: UserDTO[];
  totalRows: number;
  selectedUsersForActivation: UserDTO[];
  selectedUsersForDeactivation: UserDTO[];
  isLoading: boolean = true;
  isActivateLoading: boolean = false;
  isDeactivateLoading: boolean = false;
  columns: Column[];
  limit = Constants.PAGING_LIMIT;
  currentPage: number;
  startDate: string;
  endDate: string;
  isDownloadReportLoading: boolean = false;
  parentScreen = Constants.Screens.USERS_SCREEN;
  base64ExcelSheet: string;

  dateRangePickerLocale = {
    format: "DD/MM/YYYY",
  };
  searchUsersSubscription: Subscription = null;
  activateUsersSubscription: Subscription = null;
  deactivateUsersSubscription: Subscription = null;
  downloadUsersSubscription: Subscription = null;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private generalService: GeneralService,
    private authService: AuthService,
    private translationService: TranslationService
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    // This line is to be able to use (onSameUrlNavigation), to reload on the same page
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    if (!this.utilsService.isAdmin(this.authService.getLoggedInUser())){
      this.router.navigate(["/unauthorized"], {replaceUrl:true});
    }
    this.onSearchUsers();
  }

  ngOnDestroy() {
    if (this.searchUsersSubscription) {
      this.searchUsersSubscription.unsubscribe();
    }
    if (this.activateUsersSubscription) {
      this.activateUsersSubscription.unsubscribe();
    }
    if (this.deactivateUsersSubscription) {
      this.deactivateUsersSubscription.unsubscribe();
    }
  }

  onSearchUsers() {
    this.isLoading = true;
    this.rows = new Array();
    this.totalRows = 0;
    this.selectedUsersForActivation = new Array();
    this.selectedUsersForDeactivation = new Array();
    if (this.searchUsersSubscription) {
      this.searchUsersSubscription.unsubscribe();
    }

    let loggedInUser = this.authService.getLoggedInUser();
    let searchUsersRequestDTO: SearchUsersRequestDTO = {
      inpDateFrom: this.startDate != null ? this.startDate : "",
      inpDateTo: this.endDate != null ? this.endDate : "",
      loggedInUser: loggedInUser,
      inpTextValue: ""
    };

    this.searchUsersSubscription = this.generalService
      .postRequest(
        Constants.SEARCH_USERS_URL,
        searchUsersRequestDTO,
        null,
        null
      )
      .subscribe(this.searchUsersCallback, this.searchUsersCallbackError);
  }

  searchUsersCallback = (response) => {
    if (response.success) {
      this.columns = response.resource != null ? response.resource.columns : new Array();
      this.currentPage = 0;
      this.totalRows = response.resource != null && response.resource.data != null ? response.resource.data.length : 0;
      this.rows = response.resource != null && response.resource.data != null ? response.resource.data : new Array();
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isLoading = false;
  };

  searchUsersCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isLoading = false;
  };

  onUserSelect(users: UserDTO[]) {
    let selectedUsers = new Array();
    this.selectedUsersForActivation = new Array();
    this.selectedUsersForDeactivation = new Array();
    if (users != null) {
      selectedUsers = JSON.parse(JSON.stringify(users));
    }

    selectedUsers.forEach((element) => {
      if (element != null) {
        if (!element.isActive) {
          this.selectedUsersForActivation.push(element);
        }
        else {
          this.selectedUsersForDeactivation.push(element);
        }
      }
    });

  }

  onActivateUserClicked(user: UserDTO) {
    this.selectedUsersForActivation = new Array();
    this.selectedUsersForDeactivation = new Array();
    if (user != null) {
      let obj = JSON.parse(JSON.stringify(user));
      if (obj != null) {
        this.selectedUsersForActivation.push(obj);
        this.activateUsers();
      }
    }
  }

  activateUsers() {
    if (this.selectedUsersForActivation != null && this.selectedUsersForActivation.length > 0) {
      if (!this.isActivateLoading) {
        this.isActivateLoading = true;
        if (this.activateUsersSubscription) {
          this.activateUsersSubscription.unsubscribe();
        }

        let loggedInUser = this.authService.getLoggedInUser();
        let activateUsersRequestDTO = new ActivateUsersRequestDTO();
        activateUsersRequestDTO.userAfms = new Array();
        activateUsersRequestDTO.loggedInUser = loggedInUser;
        this.selectedUsersForActivation.forEach((element) => {
          activateUsersRequestDTO.userAfms.push(element.afm);
        });

        this.activateUsersSubscription = this.generalService
          .postRequest(
            Constants.ACTIVATE_USERS_URL,
            activateUsersRequestDTO,
            null,
            null
          )
          .subscribe(this.activateUsersCallback, this.activateUsersCallbackError);
      }
    }
  }

  activateUsersCallback = (response) => {
    if (response.success) {
      if (this.selectedUsersForActivation != null && this.selectedUsersForActivation.length == 1) {
        this.utilsService.showSwal(this.translationService.translate("users-screen.user-is-activated-successfully"), SwalType.Success);
      }
      else {
        this.utilsService.showSwal(this.translationService.translate("users-screen.users-are-activated-successfully"), SwalType.Success);
      }
      this.selectedUsersForActivation = new Array();
      this.selectedUsersForDeactivation = new Array();
      this.onSearchUsers();
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
      this.onSearchUsers(); //to avoid a bug of check all box (check all-> press activate from row->does not uncheck all and cause confusion)
    }
    this.isActivateLoading = false;
  };

  activateUsersCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.onSearchUsers(); //to avoid a bug of check all box (check all-> press activate from row->does not uncheck all and cause confusion)
    this.isActivateLoading = false;
  };

  onDeactivateUserClicked(user: UserDTO) {
    this.selectedUsersForDeactivation = new Array();
    this.selectedUsersForActivation = new Array();
    if (user != null) {
      let obj = JSON.parse(JSON.stringify(user));
      if (obj != null) {
        this.selectedUsersForDeactivation.push(obj);
        this.deactivateUsers();
      }
    }
  }

  deactivateUsers() {
    if (this.selectedUsersForDeactivation != null && this.selectedUsersForDeactivation.length > 0) {
      if (!this.isDeactivateLoading) {
        this.isDeactivateLoading = true;
        if (this.deactivateUsersSubscription) {
          this.deactivateUsersSubscription.unsubscribe();
        }

        let loggedInUser = this.authService.getLoggedInUser();
        let deactivateUsersRequestDTO = new DeactivateUsersRequestDTO();
        deactivateUsersRequestDTO.userAfms = new Array();
        deactivateUsersRequestDTO.loggedInUser = loggedInUser;
        this.selectedUsersForDeactivation.forEach((element) => {
          deactivateUsersRequestDTO.userAfms.push(element.afm);
        });

        this.deactivateUsersSubscription = this.generalService
          .postRequest(
            Constants.DEACTIVATE_USERS_URL,
            deactivateUsersRequestDTO,
            null,
            null
          )
          .subscribe(this.deactivateUsersCallback, this.deactivateUsersCallbackError);
      }
    }
  }

  deactivateUsersCallback = (response) => {
    if (response.success) {
      if (this.selectedUsersForDeactivation != null && this.selectedUsersForDeactivation.length == 1) {
        this.utilsService.showSwal(this.translationService.translate("users-screen.user-is-deactivated-successfully"), SwalType.Success);
      }
      else {
        this.utilsService.showSwal(this.translationService.translate("users-screen.users-are-deactivated-successfully"), SwalType.Success);
      }
      this.selectedUsersForDeactivation = new Array();
      this.selectedUsersForActivation = new Array();
      this.onSearchUsers();
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
      this.onSearchUsers(); //to avoid a bug of check all box (check all-> press activate from row->does not uncheck all and cause confusion)
    }
    this.isDeactivateLoading = false;
  };

  deactivateUsersCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.onSearchUsers(); //to avoid a bug of check all box (check all-> press activate from row->does not uncheck all and cause confusion)
    this.isDeactivateLoading = false;
  };


  onStartDateChanged(event) {
    if (event) {
      this.startDate = event.startDate != null ? event.startDate.format("YYYY-MM-DD") : null;
    }
  }

  onEndDateChanged(event) {
    if (event) {
      this.endDate = event.endDate != null ? event.endDate.format("YYYY-MM-DD") : null;
    }
  }

  downloadReport() {
    if (!this.isDownloadReportLoading) {
      this.isDownloadReportLoading = true;
      if (this.downloadUsersSubscription) {
        this.downloadUsersSubscription.unsubscribe();
      }

      let loggedInUser = this.authService.getLoggedInUser();
      let searchUsersRequestDTO: SearchUsersRequestDTO = {
        inpDateFrom: this.startDate != null ? this.startDate : "",
        inpDateTo: this.endDate != null ? this.endDate : "",
        loggedInUser: loggedInUser,
        inpTextValue: ""
      };

      this.searchUsersSubscription = this.generalService
        .postRequest(
          Constants.DOWNLOAD_USERS_URL,
          searchUsersRequestDTO,
          null,
          null
        )
        .subscribe(this.downloadReportCallback, this.downloadReportCallbackError);
    }
  }


  downloadReportCallback = (response) => {
    if (response.success) {
      var mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      this.base64ExcelSheet = response.resource;
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', mediaType + this.base64ExcelSheet);
      link.setAttribute('download', `users.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.utilsService.showSwal(this.translationService.translate("users-screen.users-downloaded-successfully"), SwalType.Success);

    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isDownloadReportLoading = false;
  }

  downloadReportCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isDownloadReportLoading = false;
  };



}
