export class Constants {
  
  public static SWAL_TIMEOUT = 30000;
  public static PAGING_LIMIT = 7;
  public static EMPTY_STRING_VALUE = "-";
  
  // ACCOUNT CONTROLLER
  public static LOGIN_URL = '/api/Account/Login';
  public static CHANGE_PASSWORD_URL = '/api/account/ChangePassword';
  public static FORGOT_PASSWORD_URL = '/api/account/ForgotPassword';
  public static RESET_PASSWORD_URL = '/api/account/ResetPassword';
  public static REGISTER_USER_URL = '/api/account/RegisterUser';
  public static INVITE_USER_URL = '/api/account/InviteUser';
  public static CONFIRM_EMAIL_URL = '/api/account/ConfirmEmail';
  public static SEARCH_AUCTIONS_URL = '/api/auction/SearchAuctions';
  public static INSERT_AUCTIONS_TO_EAUCTIONS_URL = '/api/auction/InsertAuctionsToEauctions';
  public static HIDE_UNHIDE_AUCTION_URL = '/api/auction/HideUnhideAuction';
  public static GET_AUCTION_DETAILS_URL = '/api/auction/AuctionDetails';
  public static SEARCH_HISTORY_URL = '/api/auction/SearchHistory';
  public static SEARCH_TRANSFERS_URL = '/api/auction/SearchTransfers';
  public static SEARCH_USERS_URL = '/api/user/SearchUsers';
  public static DOWNLOAD_USERS_URL = '/api/user/DownloadUsers';
  public static ACTIVATE_USERS_URL = '/api/user/ActivateUsers';
  public static DEACTIVATE_USERS_URL = '/api/user/DeactivateUsers';
  public static ACTIVATE_DEACTIVATE_AUTOMATIC_TRANSFER_URL = '/api/auction/ActivateDeactiveAutomaticTransfer';
  public static SEARCH_JUDGEMENTS_URL = '/api/judgement/SearchJudgements';
  public static HIDE_UNHIDE_JUDGEMENT_URL = '/api/judgement/HideUnhideAuction';

  
  // DISTRIBUTIONS
  public static CALCULATE_DISTRIBUTION_URL = '/api/Distribution/CalculateDistribution';
  public static GET_DISTRIBUTION_URL = '/api/Distribution/GetDistribution';
  public static SEARCH_DISTRIBUTIONS_URL = '/api/Distribution/SearchDistributions';
  public static INSERT_DISTRIBUTION_URL = '/api/Distribution/InsertDistribution';
  public static UPDATE_DISTRIBUTION_URL = '/api/Distribution/UpdateDistribution';
  public static DELETE_DISTRIBUTION_TABLE_URL = '/api/Distribution/DeleteDistribution';
  public static EXPORT_DISTRIBUTION_TO_EXCEL_URL = '/api/Distribution/ExportToExcel';
  public static EXPORT_DISTRIBUTION_TO_WORD_URL = '/api/Distribution/ExportToWord';

  static Screens = class {
    public static AUCTIONS_SCREEN = "AUCTIONS_SCREEN";
    public static AUCTION_DETAILS_SCREEN = "AUCTION_DETAILS_SCREEN";
    public static HISTORY_SCREEN = "HISTORY_SCREEN";
    public static TRANSFERS_SCREEN = "TRANSFERS_SCREEN";
    public static USERS_SCREEN = "USERS_SCREEN";
    public static UNAUTHORIZED_SCREEN = "UNAUTHORIZED_SCREEN";
  }

  static SessionStorage = class {
    public static LOGGED_IN_USER_KEY = 'LOGGED_IN_USER';
  }

  static Roles = class {
    public static ADMIN = 'NotaAdmin';
    public static AUCTIONEER = 'Auctioneer';
  }
}
