import { Constants } from "./constants.config";

export class Utils {
    
    static formatValue(value){
        if (value!=null){
            if (typeof value === 'string'){
                if (value.length>0){
                    return value;
                }
                return Constants.EMPTY_STRING_VALUE;
            }
            return value;
        }
        return Constants.EMPTY_STRING_VALUE;
    }
}