import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClaimDTO } from 'app/models/ClaimDTO';
import { DebtorDTO } from 'app/models/DebtorDTO';
import { DistributionDTO } from 'app/models/DistributionDTO';
import { GeneralService } from "app/services/general.service";
import { DistributionRequestDTO } from 'app/models/DistributionRequestDTO';
import { Constants } from 'app/utils/constants.config';
import {  ActivatedRoute, Router } from '@angular/router';
import { SwalType, UtilsService } from 'app/services/utils.service';
import { AuthService } from 'app/shared/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AmountDistributionDTO, AmountIncrementDTO, CategoryAmountCountDistributionDTO, CategoryDistributionDTO, CategoryDistributionWrapper } from 'app/models/AmountDistributionDTO';
import { CategoryTable } from 'app/models/CategoryTable';
import { UserRoleDTO } from 'app/models/UserRoleDTO';
import { DatePipe } from '@angular/common';
import { Observable, Subscription } from "rxjs";
import { TranslationService } from 'app/services/translation.service';
import { InsertUpdateDistributionDTO } from 'app/models/InsertUpdateDistributionDTO';
import { ComponentCanDeactivate } from 'app/services/can-deactivate-guard.service';
import { ExportRequestDTO } from 'app/models/ExportRequestDTO';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-distribution-form',
  templateUrl: './distribution-form.component.html',
  styleUrls: ['./distribution-form.component.css']
})
export class DistributionFormComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  isAddDistribution: boolean;
  distribution: DistributionDTO = new DistributionDTO();
  finalDistributionAmount: number;
  finalExpensesAmount: number;
  distributionSaving: boolean = false;
  excelLoading: boolean = false;
  wordLoading: boolean = false;
  has977A: boolean = false;
  amountsResult: AmountDistributionDTO[] = new Array();
  claimTypes975: ClaimType[] = new Array();
  claimTypes976: ClaimType[] = new Array();
  categoryTables: CategoryTable[] = new Array();
  distributionRequests: DistributionRequestDTO[] = new Array();
  noChangesApplied: boolean = true;
  lenderDistributionWrappers: CategoryDistributionWrapper[] = new Array();

  // showHideCard flags
  showAuctionDetails: boolean = true;
  showDebtors: boolean = true;
  // showResults: boolean = false;
  distributionCalculated: boolean = false;
  showGeneralAllocationResults: boolean = false;
  showDebtorsDistributionResults: boolean = false;
  showLendersDistributionResults: boolean = true;

  //debtors
  debtors: DebtorDTO[] = new Array();
  isAddDebtor: boolean = false;
  isEditDebtor: boolean = false;
  selectedDebtor: DebtorDTO = new DebtorDTO();

  // error messages
  showDebtorsErrorMessage: boolean = false;
  showDebtorsPercentageErrorMessage: boolean = false;
  showUniqueCodeErrorMessage: boolean = false;
  showAuctionAmountErrorMessage: boolean = false;
  showExecutionExpensesErrorMessage: boolean = false;
  showNotaryExpensesErrorMessage: boolean = false;
  showAfmErrorMessage: boolean = false;

  loggedInUser: UserRoleDTO = new UserRoleDTO();

  isLoading: boolean = true;
  queryParamsSubscription: Subscription = null;
  
  distributionForm = new FormGroup({
    debtorNameControl: new FormControl(''),
    debtorAfmControl: new FormControl(''),
    debtorPercentageControl: new FormControl(''),
    uniqueCodeControl: new FormControl('', [Validators.required]),
    auctionAmountControl: new FormControl('', [Validators.required]),
    executionExpensesControl: new FormControl('', [Validators.required]),
    notaryExpensesControl: new FormControl('', [Validators.required]),
    superPrivilegeTitleControl: new FormControl(''),
    superPrivilegeAmountControl: new FormControl(''),
    expensesAmountControl: new FormControl(''),
    finalAmountControl: new FormControl(''),
    claimSubcategoryControl: new FormControl(''),
    claimSubsubcategoryControl: new FormControl(''),
    claimTitleControl: new FormControl(''),
    claimAmountControl: new FormControl(''),
    mortgageAmountControl: new FormControl(""),
    claimMortgageDateControl: new FormControl(""),
    finalAmountClaimsControl: new FormControl(''),
    finalAmount976_3ClaimsControl: new FormControl(''),
});

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private generalService: GeneralService,
    private authService: AuthService,
    private datepipe: DatePipe,
    private translationService: TranslationService,
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    // This line is to be able to use (onSameUrlNavigation), to reload on the same page
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.loggedInUser = this.authService.getLoggedInUser();
    if(!this.utilsService.hasAccessToDistributionTables(this.loggedInUser)){
      if(this.utilsService.hasAccessToEAuction(this.loggedInUser)){
        this.router.navigate(["/home"], {replaceUrl:true});
      }
      else{
        this.router.navigate(["/unauthorized"], {replaceUrl:true});
      }
    }

    this.isLoading = true;
    this.clearDistributionForm();
    this.queryParamsSubscription = this.route.queryParams.subscribe(
      params => {
        let selectedDistributionId = params["id"];
        if (selectedDistributionId!=null && selectedDistributionId.length>0){
          selectedDistributionId = this.utilsService.decryptData(selectedDistributionId);
          if (selectedDistributionId!=null && selectedDistributionId.length>0){
            this.isAddDistribution = false;
            this.getDistribution(selectedDistributionId);
          } 
          else{
            this.isAddDistribution = true;
            this.isLoading = false;
          }
        }
        else{
          this.isAddDistribution = true;
          this.isLoading = false;
        }
      }
    );

    this.setCategoryMaps();
  }
  
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.noChangesApplied;
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
  }

  ngOnDestroy() {
    if (this.queryParamsSubscription){
      this.queryParamsSubscription.unsubscribe();
    }
  }

  setCategoryMaps() {
    // 975
    this.claimTypes975 = new Array();
    this.claimTypes975.push(new ClaimType("1", "1 - Απαιτήσεις για κηδεία, νοσηλεία & αποζημίωση λόγω αναπηρίας 80%", false, null));
    this.claimTypes975.push(new ClaimType("i", "i - Απαιτήσεις για την κηδεία ή τη νοσηλεία", false, "1"));
    this.claimTypes975.push(new ClaimType("ii", "ii - Απαιτήσεις αποζημίωσης λόγω αναπηρίας ποσοστού 80%", false, "1"));
    this.claimTypes975.push(new ClaimType("2", "2 - Απαιτήσεις για την παροχή τροφίμων", false, null));
    this.claimTypes975.push(new ClaimType("3", "3 - Απαιτήσεις για αμοιβές, δικηγόρους, ΦΠΑ, ασφάλιση, θάνατο, αναπηρία 67%", false, null));
    this.claimTypes975.push(new ClaimType("i", "i - Απαιτήσεις για την παροχή εξαρτημένης εργασίας, αμοιβές, έξοδα & αποζ/σεις δικηγόρων", false, "3"));
    this.claimTypes975.push(new ClaimType("ii", "ii - Απαιτήσεις από αποζημιώσεις λόγω καταγγελίας της σχέσης εργασίας", false, "3"));
    this.claimTypes975.push(new ClaimType("iii", "iii - Απαιτήσεις δικηγόρων για αποζημίωση λόγω λύσης της σύμβασης", false, "3"));
    this.claimTypes975.push(new ClaimType("iv", "iv - Απαιτήσεις δικηγόρων από δικηγορικές υπηρεσίες", false, "3"));
    this.claimTypes975.push(new ClaimType("v", "v - Απαιτήσεις του Δημοσίου από ΦΠΑ", false, "3"));
    this.claimTypes975.push(new ClaimType("vi", "vi - Απαιτήσεις των φορέων κοινωνικής ασφάλισης", false, "3"));
    this.claimTypes975.push(new ClaimType("vii", "vii - Απαιτήσεις αποζημίωσης σε περίπτωση θανάτου", false, "3"));
    this.claimTypes975.push(new ClaimType("viii", "viii - Απαιτήσεις αποζημίωσης λόγω αναπηρίας ποσοστού 67%", false, "3"));
    this.claimTypes975.push(new ClaimType("4", "4 - Απαιτήσεις αγροτών ή αγροτικών συνεταιρισμών", false, null));
    this.claimTypes975.push(new ClaimType("5", "5 - Απαιτήσεις του Δημοσίου και των οργανισμών τοπικής αυτοδιοίκησης", false, null));
    this.claimTypes975.push(new ClaimType("6", "6 - Απαιτήσεις του Συνεγγυητικού κατά του οφειλέτη", false, null));

    // 976
    this.claimTypes976 = new Array();
    this.claimTypes976.push(new ClaimType("1", "1 - Απαιτήσεις από δαπάνες για τη διατήρηση του πράγματος", false, null));
    this.claimTypes976.push(new ClaimType("2", "2 - Απαιτήσεις για τις οποίες υπάρχει ενέχυρο", true, null));
    this.claimTypes976.push(new ClaimType("3", "3 - Απαιτήσεις από δαπάνες για την παραγωγή και τη συγκομιδή καρπών", false, null));

  }

  getCategoriesForTable(table: CategoryTable){
    if (table.is975){
      return this.claimTypes975.filter(t => t.parentId == null);
    }
    else if (table.is976){
      return this.claimTypes976;
    }
  }

  getSubCategoriesForTable(table: CategoryTable) {
    if (table.is975){
      if (this.distributionForm.controls['claimSubcategoryControl'].value != null && this.distributionForm.controls['claimSubcategoryControl'].value.length > 0 && this.distributionForm.controls['claimSubcategoryControl'].valid){
        return this.claimTypes975.filter(t => t.parentId == this.distributionForm.controls['claimSubcategoryControl'].value);
      }
    }
  }

  disableSubCategoryDropdown(table: CategoryTable){
    if (table.is975){
      if (this.distributionForm.controls['claimSubcategoryControl'].value != null && this.distributionForm.controls['claimSubcategoryControl'].value.length > 0 && this.distributionForm.controls['claimSubcategoryControl'].valid){
        var subCatArray = this.claimTypes975.filter(t => t.parentId == this.distributionForm.controls['claimSubcategoryControl'].value);
        if (subCatArray != null && subCatArray.length > 0){
          this.distributionForm.controls['claimSubsubcategoryControl'].enable();
          return false
        } else {
          this.distributionForm.controls['claimSubsubcategoryControl'].disable();
          this.distributionForm.controls['claimSubsubcategoryControl'].setValue('');
          return true;
        }
      } else {
        this.distributionForm.controls['claimSubsubcategoryControl'].disable();
        this.distributionForm.controls['claimSubsubcategoryControl'].setValue('');
        return true;
      }
    }
  }

  disableMortgageFields(table: CategoryTable){
    if (table.is976){
      if (this.distributionForm.controls['claimSubcategoryControl'].value != null && this.distributionForm.controls['claimSubcategoryControl'].value.length > 0 && this.distributionForm.controls['claimSubcategoryControl'].valid){
        var selectedCat = this.claimTypes976.find(t => t.id == this.distributionForm.controls['claimSubcategoryControl'].value);
        if(selectedCat.canSelectDate){
          this.distributionForm.controls['mortgageAmountControl'].enable();
          this.distributionForm.controls['claimMortgageDateControl'].enable();
          return false;
        } else {
          this.distributionForm.controls['mortgageAmountControl'].disable();
          this.distributionForm.controls['mortgageAmountControl'].setValue('');
          this.distributionForm.controls['claimMortgageDateControl'].disable();
          this.distributionForm.controls['claimMortgageDateControl'].setValue('');
          return true;
        }
      }
      this.distributionForm.controls['mortgageAmountControl'].disable();
      this.distributionForm.controls['mortgageAmountControl'].setValue('');
      this.distributionForm.controls['claimMortgageDateControl'].disable();
      this.distributionForm.controls['claimMortgageDateControl'].setValue('');
      return true;
    }
  }

  fillDistributionForm() {
    if (!this.isAddDistribution) {
      this.distributionForm.controls['uniqueCodeControl'].setValue(this.distribution.uniqueCode);
      this.distributionForm.controls['auctionAmountControl'].setValue(new Intl.NumberFormat('el-GR').format(this.distribution.auctionAmount));
      this.distributionForm.controls['executionExpensesControl'].setValue(new Intl.NumberFormat('el-GR').format(this.distribution.executionExpenses));
      this.distributionForm.controls['notaryExpensesControl'].setValue(new Intl.NumberFormat('el-GR').format(this.distribution.notaryExpenses));
      this.fillDebtorsTable();
      this.fillClaimsTables();
    } else {
      this.distributionForm.controls['uniqueCodeControl'].setValue('');
      this.distributionForm.controls['auctionAmountControl'].setValue('');
      this.distributionForm.controls['executionExpensesControl'].setValue('');
      this.distributionForm.controls['notaryExpensesControl'].setValue('');
    }
  }

  fillDebtorsTable() {
    if (this.distribution != null && this.distribution.debtors != null && this.distribution.debtors.length > 0){
      this.debtors = new Array();
      this.distribution.debtors.forEach(debtor => {
        debtor.percentageStr = debtor.percentage != null ? debtor.percentage.toString().replace(/\./g, ',') : '0';
        this.debtors.push(debtor);
        this.setTablesForNewDebtor(debtor);
      });
    }
  }

  fillClaimsTables() {
    if (this.distribution != null && this.distribution.claims != null && this.distribution.claims.length > 0){
      this.distribution.claims.forEach(claim => {
        this.categoryTables.forEach(table => {
          if (table.debtorAfm == claim.debtor.afm) {
              let claimAmountStr = claim.amount != null ? claim.amount.toString().replace(/\./g, ',') : '0';
              let claimAmount:number = this.convertNumber(claimAmountStr, 'el-GR');
              var claimAmountEditedStr = this.getAmountInGrFormat(claimAmount, false);
              claim.amountStr = claimAmountEditedStr;
            if (claim.category == '975' && table.is975) {
              claim.subCategoryStr = this.claimTypes975.find(t=>t.id==claim.subCategory).title
              claim.subSubCategoryStr = claim.subSubCategory != null && claim.subSubCategory != "" ? this.claimTypes975.find(t=>t.id==claim.subSubCategory && t.parentId==claim.subCategory).title : null
              table.claims.push(claim);
            } else if (claim.category == '976' && table.is976) {
              if (claim.mortgageAmount != null){
                let mortgageAmountStr = claim.mortgageAmount != null ? claim.mortgageAmount.toString().replace(/\./g, ',') : '0';
                let mortgageAmount:number = this.convertNumber(mortgageAmountStr, 'el-GR');
                var mortgageAmountEditedStr = this.getAmountInGrFormat(mortgageAmount, false);
                claim.mortgageAmountStr = mortgageAmountEditedStr;
              }
              if (claim.mortgageDate != null){
                claim.mortgageDateStr = this.datepipe.transform(claim.mortgageDate, 'dd/MM/yyyy');
              }
              claim.subCategoryStr = this.claimTypes976.find(t=>t.id==claim.subCategory).title
              table.claims.push(claim);
            } else if (claim.category == 'Manuscript Lenders' && table.isManuscriptLenders){
              table.claims.push(claim);
            }
          }
          table.isExpanded = true;
        });
      });
    }
  }

  clearDistributionForm() {
    this.distributionForm.reset();
    this.distributionForm.controls['uniqueCodeControl'].setValue('');
    this.distributionForm.controls['auctionAmountControl'].setValue('');
    this.distributionForm.controls['executionExpensesControl'].setValue('');
    this.distributionForm.controls['notaryExpensesControl'].setValue('');
    this.distributionForm.controls['superPrivilegeTitleControl'].setValue('');
    this.distributionForm.controls['superPrivilegeAmountControl'].setValue('');
    this.categoryTables = new Array();
    this.debtors = new Array();
    this.cancelAllAddEditTables();
  }

  isFormValid() {
    var debtorsValid = this.debtors != null && this.debtors.length > 0;
    var sum = 0;
    if (debtorsValid){
      this.debtors.forEach(debtor => {
        sum += debtor.percentage;
      })
    }
    sum = Math.round((sum + Number.EPSILON) *100)/100;
    sum = +sum.toFixed(2);
    
    var debrorsPercentageValid = sum == 100;
    var uniqueCodeValid = this.distributionForm.controls['uniqueCodeControl'].value != null && this.distributionForm.controls['uniqueCodeControl'].value.length > 0 && !this.distributionForm.controls['uniqueCodeControl'].errors;
    var auctionAmountValid = this.distributionForm.controls['auctionAmountControl'].value != null && this.distributionForm.controls['auctionAmountControl'].value.length > 0 && !this.distributionForm.controls['auctionAmountControl'].errors;
    var executionExpensesValid = this.distributionForm.controls['executionExpensesControl'].value != null && this.distributionForm.controls['executionExpensesControl'].value.length > 0 && !this.distributionForm.controls['executionExpensesControl'].errors;
    var notaryExpensesValid = this.distributionForm.controls['notaryExpensesControl'].value != null && this.distributionForm.controls['notaryExpensesControl'].value.length > 0 && !this.distributionForm.controls['notaryExpensesControl'].errors;

    if (!debtorsValid)
      this.showDebtorsErrorMessage = true;
    else
      this.showDebtorsErrorMessage = false;
    
    if (!debrorsPercentageValid && debtorsValid)
      this.showDebtorsPercentageErrorMessage = true;
    else
      this.showDebtorsPercentageErrorMessage = false;
    
    if (!uniqueCodeValid)
      this.showUniqueCodeErrorMessage = true;
    else
      this.showUniqueCodeErrorMessage = false;
    
    if (!auctionAmountValid)
      this.showAuctionAmountErrorMessage = true;
    else
      this.showAuctionAmountErrorMessage = false;
    
    if (!executionExpensesValid)
      this.showExecutionExpensesErrorMessage = true;
    else
      this.showExecutionExpensesErrorMessage = false;
    
    if (!notaryExpensesValid)
      this.showNotaryExpensesErrorMessage = true;
    else
      this.showNotaryExpensesErrorMessage = false;
    
    if (debtorsValid && debrorsPercentageValid && uniqueCodeValid && auctionAmountValid && executionExpensesValid && notaryExpensesValid) {
      return true;
    } else {
      return false;
    }
  }

  trackByFn(index) {
    return index;
  }

  showHideGeneralAllocationResultsClicked(){
    this.showGeneralAllocationResults = !this.showGeneralAllocationResults;
  }

  showHideDebtorsDistributionResultsClicked(){
    this.showDebtorsDistributionResults = !this.showDebtorsDistributionResults;
  }

  showHideLendersDistributionResultsClicked(){
    this.showLendersDistributionResults = !this.showLendersDistributionResults;
  }

  showHideAuctionDetailsClicked(){
    this.showAuctionDetails = !this.showAuctionDetails;
  }

  showHideDebtorsClicked() {
    this.showDebtors = !this.showDebtors;
  }

  // showHideResultsClicked() {
  //   this.showResults = !this.showResults;
  // }

  calculateExpensesFinalAmount() {
    var executionExpensesStr:string = this.distributionForm.controls["executionExpensesControl"].value;
    var notaryExpensesStr:string = this.distributionForm.controls["notaryExpensesControl"].value;

    executionExpensesStr = executionExpensesStr != null ? executionExpensesStr.toString().replace(/\./g, '') : '0';
    notaryExpensesStr = notaryExpensesStr != null ? notaryExpensesStr.toString().replace(/\./g, '') : '0';
    
    let executionExpenses:number =  this.convertNumber(executionExpensesStr, 'el-GR');
    let notaryExpenses:number =  this.convertNumber(notaryExpensesStr, 'el-GR');
    this.finalExpensesAmount = executionExpenses+notaryExpenses;

    this.finalExpensesAmount = Math.round((this.finalExpensesAmount + Number.EPSILON) *100)/100;
    return this.getAmountInGrFormat(this.finalExpensesAmount, true);
  }

  calculateFinalAmount() {
    var auctionAmountStr:string = this.distributionForm.controls["auctionAmountControl"].value;
    var executionExpensesStr:string = this.distributionForm.controls["executionExpensesControl"].value;
    var notaryExpensesStr:string = this.distributionForm.controls["notaryExpensesControl"].value;
    var superPrivilegeAmountStr:string = this.distributionForm.controls["superPrivilegeAmountControl"].value;

    auctionAmountStr = auctionAmountStr != null ? auctionAmountStr.toString().replace(/\./g, '') : '0';
    executionExpensesStr = executionExpensesStr != null ? executionExpensesStr.toString().replace(/\./g, '') : '0';
    notaryExpensesStr = notaryExpensesStr != null ? notaryExpensesStr.toString().replace(/\./g, '') : '0';
    superPrivilegeAmountStr = superPrivilegeAmountStr != null ? superPrivilegeAmountStr.toString().replace(/\./g, '') : '0';
    let auctionAmount:number = this.convertNumber(auctionAmountStr, 'el-GR');
    let executionExpenses:number =  this.convertNumber(executionExpensesStr, 'el-GR');
    let notaryExpenses:number =  this.convertNumber(notaryExpensesStr, 'el-GR');
    let superPrivilegeAmount:number = this.convertNumber(superPrivilegeAmountStr, 'el-GR');
    this.finalDistributionAmount = auctionAmount-executionExpenses-notaryExpenses-superPrivilegeAmount;

    this.finalDistributionAmount = Math.round((this.finalDistributionAmount + Number.EPSILON) *100)/100;
    return this.getAmountInGrFormat(this.finalDistributionAmount, true);
  }

  onSaveDistributionClicked(){
    if (this.distributionRequests == null || this.distributionRequests.length == 0)
      return;
    
    let requestModel: InsertUpdateDistributionDTO = new InsertUpdateDistributionDTO();
    requestModel.model = this.distributionRequests;
    requestModel.user = this.loggedInUser;
    this.distributionSaving = true;
    if (this.isAddDistribution)
      this.generalService.postRequest(Constants.INSERT_DISTRIBUTION_URL, requestModel, null, null).subscribe(this.saveDistributionCallback, this.saveDistributionCallbackError);
    else
      this.generalService.postRequest(Constants.UPDATE_DISTRIBUTION_URL, requestModel, [this.distribution.id], ['distributionId']).subscribe(this.saveDistributionCallback, this.saveDistributionCallbackError);
  }

  saveDistributionCallback = (response) => {
    if (response.success) {
      this.noChangesApplied = true;
      if (this.isAddDistribution)
        this.utilsService.showSwal(this.translationService.translate("distribution-form-screen.insert-distribution-success"), SwalType.Success);//.then(() => { this.router.navigate(['/distributions']); });
      else 
        this.utilsService.showSwal(this.translationService.translate("distribution-form-screen.update-distribution-success"), SwalType.Success);//.then(() => { this.router.navigate(['/distributions']); });
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.distributionSaving = false;
    // get updated distribution
    this.isLoading = true;
    this.isAddDistribution = false;
    this.clearDistributionForm();
    this.getDistribution(response.resource);
  }

  saveDistributionCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.distributionSaving = false;
  };

  onCalculateDistributionClicked(){
    if (!this.isFormValid())
      return;

    this.distributionSaving = true;
    this.distributionRequests = new Array();
    this.debtors.forEach(debtor => {
      const distributionRequestDTO = new DistributionRequestDTO;
      
      var auctionAmountStr:string = this.distributionForm.controls["auctionAmountControl"].value;
      var executionExpensesStr:string = this.distributionForm.controls["executionExpensesControl"].value;
      var notaryExpensesStr:string = this.distributionForm.controls["notaryExpensesControl"].value;
      var superPrivilegeAmountStr:string = this.distributionForm.controls["superPrivilegeAmountControl"].value;
      auctionAmountStr = auctionAmountStr != null ? auctionAmountStr.toString().replace(/\./g, '') : '0';
      executionExpensesStr = executionExpensesStr != null ? executionExpensesStr.toString().replace(/\./g, '') : '0';
      notaryExpensesStr = notaryExpensesStr != null ? notaryExpensesStr.toString().replace(/\./g, '') : '0';
      superPrivilegeAmountStr = superPrivilegeAmountStr != null ? superPrivilegeAmountStr.toString().replace(/\./g, '') : '0';
      let auctionAmount:number = this.convertNumber(auctionAmountStr, 'el-GR');
      let executionExpenses:number =  this.convertNumber(executionExpensesStr, 'el-GR');
      let notaryExpenses:number =  this.convertNumber(notaryExpensesStr, 'el-GR');
      let superPrivilegeAmount:number = this.convertNumber(superPrivilegeAmountStr, 'el-GR');

      distributionRequestDTO.debtorName = debtor.fullName;
      distributionRequestDTO.debtorAfm = debtor.afm;
      distributionRequestDTO.debtorPercentage = debtor.percentage;
      distributionRequestDTO.uniqueCode = this.distributionForm.controls['uniqueCodeControl'].value;
      distributionRequestDTO.auctionAmount = auctionAmount*debtor.percentage/100; //auctionAmount/this.debtors.length; 
      distributionRequestDTO.executionExpenses = executionExpenses*debtor.percentage/100; //executionExpenses/this.debtors.length;
      distributionRequestDTO.notaryExpenses = notaryExpenses*debtor.percentage/100; //notaryExpenses/this.debtors.length;
      distributionRequestDTO.superPrivilegeTitle = this.distributionForm.controls['superPrivilegeTitleControl'].value;
      distributionRequestDTO.superPrivilegeAmount = superPrivilegeAmount*debtor.percentage/100; // superPrivilegeAmount/this.debtors.length;
      distributionRequestDTO.finalDistributionAmount = this.finalDistributionAmount*debtor.percentage/100; // this.finalDistributionAmount/this.debtors.length;
      distributionRequestDTO.finalExpensesAmount = this.finalExpensesAmount*debtor.percentage/100; // this.finalExpensesAmount/this.debtors.length;
      distributionRequestDTO.auctioneerUserName = this.loggedInUser.username;

      let totalAmount975 = 0;
      let totalAmount976 = 0;
      let totalAmount976_3 = 0;
      let totalAmountManuscriptLenders = 0;

      this.categoryTables.forEach(table => {
        if (table.debtorAfm == debtor.afm){
          if (table.is975){
            table.claims.forEach(claim => {
              totalAmount975 += claim.amount;
            });
          }
          if (table.is976){
            table.claims.forEach(claim => {
              if (claim.subCategory == '3')
                totalAmount976_3 += claim.amount;
              else {
                var amount = claim.subCategory == '2' && claim.mortgageAmount != null && claim.mortgageAmount > 0 && claim.mortgageAmount < claim.amount ? claim.mortgageAmount : claim.amount;
                totalAmount976 += amount;
              }
            });
          }
          if (table.isManuscriptLenders){
            table.claims.forEach(claim => {
              totalAmountManuscriptLenders += claim.amount;
            });
          }
        }
      });

      distributionRequestDTO.totalAmountFor975 = totalAmount975;
      distributionRequestDTO.totalAmountFor976 = totalAmount976;
      distributionRequestDTO.totalAmountFor976_3 = totalAmount976_3;
      distributionRequestDTO.totalAmountForManuscriptLenders = totalAmountManuscriptLenders;

      this.categoryTables.forEach(category => {
        if (category.is975 && category.debtorAfm == debtor.afm){
          distributionRequestDTO.claims975 = new Array();
          distributionRequestDTO.claims975 = category.claims;
        } else if (category.is976 && category.debtorAfm == debtor.afm) {
          distributionRequestDTO.claims976 = new Array();
          distributionRequestDTO.claims976 = category.claims;
        } else if (category.isManuscriptLenders && category.debtorAfm == debtor.afm) {
          distributionRequestDTO.claimsManuscriptLenders = new Array();
          distributionRequestDTO.claimsManuscriptLenders = category.claims;
        }
      });

      this.distributionRequests.push(distributionRequestDTO);
    });

    this.generalService.postRequest(Constants.CALCULATE_DISTRIBUTION_URL, this.distributionRequests, null, null).subscribe(this.calculateDistributionCallback, this.calculateDistributionCallbackError);
  }

  calculateDistributionCallback = (response) => {
    if (response.success) {
      this.amountsResult = new Array();
      this.amountsResult = response.resource;

      // set categoryDistributionWrappers
      this.amountsResult.forEach(amountResult => {
        let wrappers: CategoryDistributionWrapper[] = new Array();
        if (amountResult.categoryDistributions != null && amountResult.categoryDistributions.length > 0){
          amountResult.categoryDistributions.forEach(categoryDistribution => {
            let parentWrapper: CategoryDistributionWrapper = new CategoryDistributionWrapper();
            parentWrapper.title = categoryDistribution.title;
            parentWrapper.amount = null;
            parentWrapper.isParent = true;
            parentWrapper.parentTitle = null;
            wrappers.push(parentWrapper);
            categoryDistribution.claimDistributions.forEach(claim => {
              let wrapper = new CategoryDistributionWrapper();
              wrapper.title = claim.title;
              wrapper.amount = claim.amount;
              wrapper.isParent = false;
              wrapper.parentTitle = parentWrapper.title;
              wrappers.push(wrapper);
            });
          });
        }

        amountResult.categoryDistributionWrappers = wrappers;
      });

      var allCategoryDistributions: CategoryDistributionDTO[] = new Array();
      
      // set lenderDistributionWrappers
      allCategoryDistributions.push(new CategoryDistributionDTO({title: "Άρθρο 975", claimDistributions: new Array(), percentage: 0}));
      allCategoryDistributions.push(new CategoryDistributionDTO({title: "Άρθρο 976", claimDistributions: new Array(), percentage: 0}));
      allCategoryDistributions.push(new CategoryDistributionDTO({title: "Έγχειρόγραφοι Δανειστές", claimDistributions: new Array(), percentage: 0}));
      this.amountsResult.forEach(amountResult => {
        if (amountResult.categoryDistributions != null && amountResult.categoryDistributions.length > 0){
          amountResult.categoryDistributions.forEach(catDistr => {
            if (catDistr.title.includes("975")){
              if (catDistr.claimDistributions != null && catDistr.claimDistributions.length > 0){
                catDistr.claimDistributions.forEach(newClaim => {
                  var cat975catDistr = allCategoryDistributions.find(acd => acd.title.includes('975'));
                  var existingClaim = cat975catDistr.claimDistributions.find(c => c.title == newClaim.title);
                  if (existingClaim != null){
                    // if child exists add amount
                    existingClaim.amount += newClaim.amount;
                  } else {
                    // else add new child
                    cat975catDistr.claimDistributions.push(newClaim);
                  }
                })
              }
            } else if (catDistr.title.includes("976")) {
              if (catDistr.claimDistributions != null && catDistr.claimDistributions.length > 0){
                catDistr.claimDistributions.forEach(newClaim => {
                  var cat976catDistr = allCategoryDistributions.find(acd => acd.title.includes('976'));
                  var existingClaim = cat976catDistr.claimDistributions.find(c => c.title == newClaim.title);
                  if (existingClaim != null){
                    // if child exists add amount
                    existingClaim.amount += newClaim.amount;
                  } else {
                    // else add new child
                    cat976catDistr.claimDistributions.push(newClaim);
                  }
                })
              }
            } else {
              catDistr.claimDistributions.forEach(newClaim => {
                var catMLcatDistr = allCategoryDistributions.find(acd => acd.title.includes('Έγχειρόγραφοι Δανειστές'));
                var existingClaim = catMLcatDistr.claimDistributions.find(c => c.title == newClaim.title);
                if (existingClaim != null){
                  // if child exists add amount
                  existingClaim.amount += newClaim.amount;
                } else {
                  // else add new child
                  catMLcatDistr.claimDistributions.push(newClaim);
                }
              })
            }
          });
        }
      });
      // remove empty category distributions
      for (let i=0; i<allCategoryDistributions.length; i++){
        if (allCategoryDistributions[i].claimDistributions == null || allCategoryDistributions[i].claimDistributions.length == 0){
          allCategoryDistributions.splice(i, 1);
          i--;
        }
      }
      
      this.lenderDistributionWrappers = new Array();
      allCategoryDistributions.forEach(categoryDistribution => {
        let parentWrapper: CategoryDistributionWrapper = new CategoryDistributionWrapper();
        parentWrapper.title = categoryDistribution.title;
        parentWrapper.amount = null;
        parentWrapper.isParent = true;
        parentWrapper.parentTitle = null;
        this.lenderDistributionWrappers.push(parentWrapper);
        categoryDistribution.claimDistributions.forEach(claim => {
          let wrapper = new CategoryDistributionWrapper();
          wrapper.title = claim.title;
          wrapper.amount = claim.amount;
          wrapper.isParent = false;
          wrapper.parentTitle = parentWrapper.title;
          this.lenderDistributionWrappers.push(wrapper);
        });
      });

      this.showAuctionDetails = false;
      this.showDebtors = false;
      this.categoryTables.forEach(table => {
        table.isExpanded = false;
      });
      this.distributionCalculated = true;
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.distributionSaving = false;
  };

  calculateDistributionCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.distributionSaving = false;
  };

  getDistribution(id){
    this.isLoading = true;
    this.generalService.postRequest(Constants.GET_DISTRIBUTION_URL, this.loggedInUser, [id], ["distributionId"]).subscribe(this.getDistributionCallback, this.getDistributionCallbackError);
  }

  getDistributionCallback = (response) => {
    if (response.success) {
      this.distribution = new DistributionDTO();
      this.distribution = response.resource;
      this.fillDistributionForm();
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isLoading = false;
  };

  getDistributionCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isLoading = false;
  };

  // Debtors Table Functions

  onAddDebtorClicked() {
    this.cancelAllAddEditTables();
    this.isAddDebtor = true;
  }

  onEditDebtorClicked(debtor) {
    this.cancelAllAddEditTables();
    this.isEditDebtor = true;
    this.selectedDebtor = debtor;
    this.distributionForm.controls['debtorNameControl'].setValue(this.selectedDebtor.fullName);
    this.distributionForm.controls['debtorAfmControl'].setValue(this.selectedDebtor.afm);
    this.distributionForm.controls['debtorPercentageControl'].setValue(this.selectedDebtor.percentageStr);
  }

  onDeleteDebtorClicked(debtor) {
    this.removeTablesForDeletedDebtor(debtor);
    this.debtors.splice(this.debtors.indexOf(debtor), 1);
    this.noChangesApplied = false;
  }

  onSaveEditDebtorClicked(debtor) {
    var percentageStr:string = this.distributionForm.controls["debtorPercentageControl"].value;
    percentageStr = percentageStr != null ? percentageStr.toString().replace(/\./g, '') : '0';
    let percentage:number = this.convertNumber(percentageStr, 'el-GR');
    percentage = Math.round((percentage + Number.EPSILON) *100)/100;
    
    if (this.distributionForm.controls['debtorNameControl'].value != null && this.distributionForm.controls['debtorNameControl'].value.length > 0 && 
    this.distributionForm.controls['debtorAfmControl'].value != null && this.distributionForm.controls['debtorAfmControl'].value.length > 0 && 
    this.distributionForm.controls['debtorPercentageControl'].value != null && this.distributionForm.controls['debtorPercentageControl'].value.length > 0 && 
    percentage != null && percentage > 0  && percentage <= 100) {
      if (!this.validateAfm(this.distributionForm.controls['debtorAfmControl'].value)){
        this.showAfmErrorMessage = true;
        return;
      } else {
        this.showAfmErrorMessage = false;
        this.showDebtorsErrorMessage = false;
      }
      this.debtors[this.debtors.indexOf(debtor)].fullName = this.distributionForm.controls['debtorNameControl'].value;
      this.debtors[this.debtors.indexOf(debtor)].afm = this.distributionForm.controls['debtorAfmControl'].value;
      this.debtors[this.debtors.indexOf(debtor)].percentage = percentage;
      this.debtors[this.debtors.indexOf(debtor)].percentageStr = this.distributionForm.controls['debtorPercentageControl'].value;
      this.clearDebtorFields();
      this.isEditDebtor = false;
      this.setTablesForEditedDebtor(debtor);
    }
    this.noChangesApplied = false;
  }

  onCancelEditDebtorClicked() {
    this.isEditDebtor = false;
    this.showAfmErrorMessage = false;
    this.showDebtorsErrorMessage = false;
    this.selectedDebtor = new DebtorDTO();
    this.clearDebtorFields();
  }

  onSaveNewDebtorClicked() {
    var percentageStr:string = this.distributionForm.controls["debtorPercentageControl"].value;
    percentageStr = percentageStr != null ? percentageStr.toString().replace(/\./g, '') : '0';
    let percentage:number = this.convertNumber(percentageStr, 'el-GR');
    percentage = Math.round((percentage + Number.EPSILON) *100)/100;
    
    if (this.distributionForm.controls['debtorNameControl'].value != null && this.distributionForm.controls['debtorNameControl'].value.length > 0 && 
    this.distributionForm.controls['debtorAfmControl'].value != null && this.distributionForm.controls['debtorAfmControl'].value.length > 0 && 
    this.distributionForm.controls['debtorPercentageControl'].value != null && this.distributionForm.controls['debtorPercentageControl'].value.length > 0 && 
    percentage != null && percentage > 0  && percentage <= 100) {
      if (!this.validateAfm(this.distributionForm.controls['debtorAfmControl'].value)){
        this.showAfmErrorMessage = true;
        return;
      } else {
        this.showAfmErrorMessage = false;
        this.showDebtorsErrorMessage = false;
      }
      let debtor = new DebtorDTO();
      debtor.fullName = this.distributionForm.controls['debtorNameControl'].value;
      debtor.afm = this.distributionForm.controls['debtorAfmControl'].value;
      debtor.percentage = percentage;
      debtor.percentageStr = this.distributionForm.controls['debtorPercentageControl'].value;
      this.debtors.push(debtor);
      this.clearDebtorFields();
      this.isAddDebtor = false;
      this.setTablesForNewDebtor(debtor);
    }
    this.noChangesApplied = false;
  }

  onCancelAddDebtorClicked() {
    this.isAddDebtor = false;
    this.showAfmErrorMessage = false;
    this.showDebtorsErrorMessage = false;
    this.clearDebtorFields();
  }

  clearDebtorFields() {
    this.distributionForm.controls['debtorNameControl'].setValue('');
    this.distributionForm.controls['debtorAfmControl'].setValue('');
    this.distributionForm.controls['debtorPercentageControl'].setValue('');
  }

  setTablesForNewDebtor(debtor: DebtorDTO) {
    let ct = new CategoryTable(); 
    ct.isExpanded = false;
    ct.debtorName = debtor.fullName;
    ct.debtorAfm = debtor.afm;
    ct.claims = new Array();
    ct.isAddClaim = false;
    ct.isEditClaim = false;
    ct.selectedClaim = new ClaimDTO();
    ct.is975 = true;
    ct.is976 = false;
    ct.isManuscriptLenders = false;
    this.categoryTables.push(ct);

    ct = new CategoryTable();
    ct.isExpanded = false;
    ct.debtorName = debtor.fullName;
    ct.debtorAfm = debtor.afm;
    ct.claims = new Array();
    ct.isAddClaim = false;
    ct.isEditClaim = false;
    ct.selectedClaim = new ClaimDTO();
    ct.is975 = false;
    ct.is976 = true;
    ct.isManuscriptLenders = false;
    this.categoryTables.push(ct);

    ct = new CategoryTable();
    ct.isExpanded = false;
    ct.debtorName = debtor.fullName;
    ct.debtorAfm = debtor.afm;
    ct.claims = new Array();
    ct.isAddClaim = false;
    ct.isEditClaim = false;
    ct.selectedClaim = new ClaimDTO();
    ct.is975 = false;
    ct.is976 = false;
    ct.isManuscriptLenders = true;
    this.categoryTables.push(ct);
  }

  setTablesForEditedDebtor(debtor: DebtorDTO) {
    let index = this.debtors.indexOf(debtor)*3;
    this.categoryTables[index].debtorAfm = debtor.afm;
    this.categoryTables[index].debtorName = debtor.fullName;
    this.categoryTables[index+1].debtorAfm = debtor.afm;
    this.categoryTables[index+1].debtorName = debtor.fullName;
    this.categoryTables[index+2].debtorAfm = debtor.afm;
    this.categoryTables[index+2].debtorName = debtor.fullName;
  }

  removeTablesForDeletedDebtor(debtor: DebtorDTO) {
    let index:number = this.debtors.indexOf(debtor)*3;
    this.categoryTables.splice(index,3);
  }

  // General Table Functions

  onAddClaimClicked(table: CategoryTable) {
    this.cancelAllAddEditTables();
    this.categoryTables[this.categoryTables.indexOf(table)].isAddClaim = true;
  }

  onEditClaimClicked(table: CategoryTable, claim: ClaimDTO) {
    this.cancelAllAddEditTables();
    this.categoryTables[this.categoryTables.indexOf(table)].isEditClaim = true;
    this.categoryTables[this.categoryTables.indexOf(table)].selectedClaim = claim;

    var claimAmount = this.getAmountInGrFormat(claim.amount, false);
    var mortgageAmount = claim.mortgageAmount != null ? this.getAmountInGrFormat(claim.mortgageAmount, false) : "";

    this.distributionForm.controls['claimSubcategoryControl'].setValue(claim.subCategory);
    this.distributionForm.controls['claimSubsubcategoryControl'].setValue(claim.subSubCategory);
    this.distributionForm.controls['claimTitleControl'].setValue(claim.title);
    this.distributionForm.controls['claimAmountControl'].setValue(claimAmount);
    this.distributionForm.controls['mortgageAmountControl'].setValue(mortgageAmount);
    if (claim.mortgageDate.toString().includes('T')){
      this.distributionForm.controls['claimMortgageDateControl'].setValue(claim.mortgageDate.toString().split('T')[0]);
    } else {
      this.distributionForm.controls['claimMortgageDateControl'].setValue(claim.mortgageDate);
    }
  }
  
  onDeleteClaimClicked(table: CategoryTable, claim: ClaimDTO) {
    let tableIndex = this.categoryTables.indexOf(table);
    let claimIndex = this.categoryTables[tableIndex].claims.indexOf(claim);
    this.categoryTables[tableIndex].claims.splice(claimIndex, 1);
    this.categoryTables[tableIndex].claims.forEach(claim => {
      claim.index = this.categoryTables[tableIndex].claims.indexOf(claim)+1;
    });
    this.noChangesApplied = false;
  }

  onSaveEditClaimClicked(table: CategoryTable, claim: ClaimDTO) {
    var claimAmountStr:string = this.distributionForm.controls["claimAmountControl"].value;
    var mortgageAmountStr:string = this.distributionForm.controls["mortgageAmountControl"].value;
    claimAmountStr = claimAmountStr != null ? claimAmountStr.toString().replace(/\./g, '') : '0';
    mortgageAmountStr = mortgageAmountStr != null ? mortgageAmountStr.toString().replace(/\./g, '') : '0';
    let claimAmount:number = this.convertNumber(claimAmountStr, 'el-GR');
    let mortgageAmount:number = this.convertNumber(mortgageAmountStr, 'el-GR');
    if ( (table.isManuscriptLenders || (this.distributionForm.controls['claimSubcategoryControl'].value != null && this.distributionForm.controls['claimSubcategoryControl'].value.length > 0)) &&
      this.claimSubSubCategoryIsValid(table) &&
      this.distributionForm.controls['claimTitleControl'].value != null && this.distributionForm.controls['claimTitleControl'].value.length > 0 &&
      this.distributionForm.controls['claimAmountControl'].value != null && this.distributionForm.controls['claimAmountControl'].value.length > 0 &&
      this.claimMortgageDateIsValid(table)) {
        let tableIndex = this.categoryTables.indexOf(table);
        let claimIndex = this.categoryTables[tableIndex].claims.indexOf(claim);
        this.categoryTables[tableIndex].claims[claimIndex].subCategory = this.distributionForm.controls['claimSubcategoryControl'].value;
        this.categoryTables[tableIndex].claims[claimIndex].subCategoryStr = table.is975 ? this.claimTypes975.find(t=>t.id==this.distributionForm.controls['claimSubcategoryControl'].value).title : table.is976 ? this.claimTypes976.find(t=>t.id==this.distributionForm.controls['claimSubcategoryControl'].value).title : "";
        this.categoryTables[tableIndex].claims[claimIndex].subSubCategory = this.distributionForm.controls['claimSubsubcategoryControl'].value;
        this.categoryTables[tableIndex].claims[claimIndex].subSubCategoryStr = table.is975 && this.categoryTables[tableIndex].claims[claimIndex].subSubCategory != null && this.categoryTables[tableIndex].claims[claimIndex].subSubCategory != "" ? this.claimTypes975.find(t=>t.id==this.distributionForm.controls['claimSubsubcategoryControl'].value&&t.parentId==this.categoryTables[tableIndex].claims[claimIndex].subCategory).title : "";
        this.categoryTables[tableIndex].claims[claimIndex].title = this.distributionForm.controls['claimTitleControl'].value;
        this.categoryTables[tableIndex].claims[claimIndex].amount = claimAmount;
        this.categoryTables[tableIndex].claims[claimIndex].amountStr = this.distributionForm.controls["claimAmountControl"].value;
        this.categoryTables[tableIndex].claims[claimIndex].mortgageAmount = mortgageAmount;
        this.categoryTables[tableIndex].claims[claimIndex].mortgageAmountStr = this.distributionForm.controls["mortgageAmountControl"].value;
        this.categoryTables[tableIndex].claims[claimIndex].mortgageDate = this.distributionForm.controls['claimMortgageDateControl'].value;
        this.categoryTables[tableIndex].claims[claimIndex].mortgageDateStr = this.datepipe.transform(this.categoryTables[tableIndex].claims[claimIndex].mortgageDate, 'dd/MM/yyyy');
        this.cancelAllAddEditTables();
    }
    this.noChangesApplied = false;
  }

  onCancelEditClaimClicked(table: CategoryTable) {
    this.categoryTables[this.categoryTables.indexOf(table)].isEditClaim = false;
    this.categoryTables[this.categoryTables.indexOf(table)].selectedClaim = new ClaimDTO();
    this.cancelAllAddEditTables();
  }

  onSaveNewClaimClicked(table: CategoryTable) {
    var claimAmountStr:string = this.distributionForm.controls["claimAmountControl"].value;
    var mortgageAmountStr:string = this.distributionForm.controls["mortgageAmountControl"].value;
    claimAmountStr = claimAmountStr != null ? claimAmountStr.toString().replace(/\./g, '') : '0';
    mortgageAmountStr = mortgageAmountStr != null ? mortgageAmountStr.toString().replace(/\./g, '') : '0';
    let claimAmount:number = this.convertNumber(claimAmountStr, 'el-GR');
    let mortgageAmount:number = this.convertNumber(mortgageAmountStr, 'el-GR');
    var claimAmountEditedStr = this.getAmountInGrFormat(claimAmount, false);
    var mortgageAmountEditedStr = this.getAmountInGrFormat(mortgageAmount, false);
    if ( (table.isManuscriptLenders || (this.distributionForm.controls['claimSubcategoryControl'].value != null && this.distributionForm.controls['claimSubcategoryControl'].value.length > 0)) &&
    this.claimSubSubCategoryIsValid(table) &&
    this.distributionForm.controls['claimTitleControl'].value != null && this.distributionForm.controls['claimTitleControl'].value.length > 0 &&
    this.distributionForm.controls['claimAmountControl'].value != null && this.distributionForm.controls['claimAmountControl'].value.length > 0 &&
    this.claimMortgageDateIsValid(table)) {
      let claim = new ClaimDTO();
      claim.category = table.is975 ? '975' : table.is976 ? '976' : 'Manuscript Lender';
      claim.subCategory = this.distributionForm.controls['claimSubcategoryControl'].value;
      claim.subCategoryStr = table.is975 ? this.claimTypes975.find(t=>t.id==this.distributionForm.controls['claimSubcategoryControl'].value).title : table.is976 ? this.claimTypes976.find(t=>t.id==this.distributionForm.controls['claimSubcategoryControl'].value).title : "";;
      claim.subSubCategory = this.distributionForm.controls['claimSubsubcategoryControl'].value;
      claim.subSubCategoryStr = table.is975 && claim.subSubCategory != null && claim.subSubCategory != "" ? this.claimTypes975.find(t=>t.id==this.distributionForm.controls['claimSubsubcategoryControl'].value&&t.parentId==claim.subCategory).title : "";
      claim.title = this.distributionForm.controls['claimTitleControl'].value;
      claim.amount = claimAmount;
      claim.amountStr = claimAmountEditedStr;
      claim.mortgageAmount = mortgageAmount;
      claim.mortgageAmountStr = mortgageAmountEditedStr;
      claim.mortgageDate = this.distributionForm.controls['claimMortgageDateControl'].value;
      claim.mortgageDateStr = this.datepipe.transform(claim.mortgageDate, 'dd/MM/yyyy');
      claim.index = table.claims.length+1;
      this.categoryTables[this.categoryTables.indexOf(table)].claims.push(claim);
      this.cancelAllAddEditTables();
    }
    this.noChangesApplied = false;
  }

  onCancelAddClaimClicked() {
    this.cancelAllAddEditTables();
  }

  cancelAllAddEditTables(){
    this.isAddDebtor = false;
    this.isEditDebtor = false;
    this.categoryTables.forEach(table => {
      table.isEditClaim = false;
      table.isAddClaim = false;
    });
    this.distributionForm.controls['claimSubcategoryControl'].setValue('');
    this.distributionForm.controls['claimSubsubcategoryControl'].setValue('');
    this.distributionForm.controls['claimTitleControl'].setValue('');
    this.distributionForm.controls['claimAmountControl'].setValue('');
    this.distributionForm.controls['mortgageAmountControl'].setValue('');
    this.distributionForm.controls['claimMortgageDateControl'].setValue('');
  }

  calculateTotalClaimAmount(is975: boolean = false, is976: boolean = false, isManuscriptLenders: boolean = false){
    var returnValue = 0;
    let tables: CategoryTable[] = new Array(); 
    if (is975)
      tables = this.categoryTables.filter(t => t.is975);
    else if (is976)
      tables = this.categoryTables.filter(t => t.is976);
    else if (isManuscriptLenders)
      tables = this.categoryTables.filter(t => t.isManuscriptLenders);

    if (this.categoryTables != null && this.categoryTables.length > 0 && tables != null && tables.length > 0) {
      tables.forEach(table => {
        if (this.categoryTables[this.categoryTables.indexOf(table)].claims != null && this.categoryTables[this.categoryTables.indexOf(table)].claims.length > 0){
          this.categoryTables[this.categoryTables.indexOf(table)].claims.forEach(claim => {
            returnValue += claim.amount;
          });
        }
      });
      return this.getAmountInGrFormat(returnValue, true);
    } else {
      return '0';
    }
  }

  calculateDebtorTotalClaimAmount(categoryAmountCountDistributions: CategoryAmountCountDistributionDTO[]){
    var returnValue = 0;
    categoryAmountCountDistributions.forEach(category => {
      returnValue += category.claimAmount;
    });
    return this.getAmountInGrFormat(returnValue, true);
  }

  calculateFinalAmountClaims(table: CategoryTable) {
    var returnValue = 0;

    if (this.categoryTables != null && this.categoryTables.length > 0 && table != null && this.categoryTables[this.categoryTables.indexOf(table)].claims != null && this.categoryTables[this.categoryTables.indexOf(table)].claims.length > 0){
      this.categoryTables[this.categoryTables.indexOf(table)].claims.forEach(claim => {
        if (table.is976){
          if (claim.subCategory != '3'){
            if (claim.subCategory == '2'){
              let addedAmount = claim.mortgageAmount != null && claim.mortgageAmount > 0 && claim.mortgageAmount < claim.amount ? claim.mortgageAmount : claim.amount;
              returnValue += addedAmount;
            } else {
              returnValue += claim.amount;
            }
          }
        }
        else {
          returnValue += claim.amount;
        }
      });
  
      return this.getAmountInGrFormat(returnValue, true);
    } else {
      return '0';
    }
  }

  calculateFinalAmount976_3Claims(table: CategoryTable) {
    var returnValue = 0;
    this.categoryTables[this.categoryTables.indexOf(table)].claims.forEach(claim => {
      if (claim.subCategory == '3'){
        returnValue += claim.amount;
      }
    });

    return this.getAmountInGrFormat(returnValue, true);
  }

  claimSubSubCategoryIsValid(table: CategoryTable){
    if (table.is975){
      var valueArray = this.claimTypes975.filter(t => t.parentId == this.distributionForm.controls['claimSubcategoryControl'].value);
      if (valueArray != null && valueArray.length > 0){
        if (this.distributionForm.controls['claimSubsubcategoryControl'].value){
          return true;
        }
        return false
      }
      return true;
    }
    return true;
  }

  claimMortgageDateIsValid(table: CategoryTable){
    if (table.is976){
      var boolValue = this.claimTypes976.find(t => t.id == this.distributionForm.controls['claimSubcategoryControl'].value).canSelectDate
      if (boolValue){
        if (this.distributionForm.controls['claimMortgageDateControl'].value != null && this.distributionForm.controls['claimMortgageDateControl'].value.length > 0){
          return true;
        }
        return false
      }
      return true;
    }
    return true;
  }

  onKeyDownEvent(event: any, inputFormControlName: string){
    setTimeout(() => {
      let parts = event.target.value.split(",");
      let v = parts[0].replace(/\D/g, "");
      let dec = parts[1];
      let n = new Intl.NumberFormat('el-GR').format(v);
      n = dec !== undefined ? n + "," + dec : n;
      this.distributionForm.controls[inputFormControlName].setValue(n);
    })
    this.noChangesApplied = false;
  }
  
  convertNumber(num, locale) : number {
    const { format } = new Intl.NumberFormat(locale);
    const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
    return +num
    .replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '')
    .replace(decimalSign, '.');
  };

  getTotalAmountCorrectNumberFormat(amount) {
    if (amount == null)
      return "";

    return this.getAmountInGrFormat(amount, true);
  }

  getCoverPercentage(amount, totalAmount){
    return this.getPercentageInGrFormat((amount/this.getClaimTotalAmountFormBreakdown(totalAmount)), false); // *100
  }

  getCoverForLenderAndClaimsPercentage(amount, totalAmount){
    return this.getPercentageInGrFormat((amount/totalAmount), false); // *100
  }

  getClaimTotalAmountFormBreakdown(categoryAmountDistributions: CategoryAmountCountDistributionDTO[]){
    var claimSum: number = 0; 
    if (categoryAmountDistributions != null && categoryAmountDistributions.length > 0){
      categoryAmountDistributions.forEach(categoryAmountDistribution => {
        claimSum += categoryAmountDistribution.claimAmount;
      });
    }

    return claimSum;
  }

  getIncrementAmountText(amountIncrement: AmountIncrementDTO){
    if (amountIncrement != null && amountIncrement.amountFor != null && amountIncrement.categoryAmounts != null && amountIncrement.categoryAmounts.length > 0){
      var str: string = 'Αναλογούν ποσό διανομής: <b>' + this.getTotalAmountCorrectNumberFormat(amountIncrement.amountFor) + '</b>';
      amountIncrement.categoryAmounts.forEach(categoryAmount => {
        str = str + '<br/>Προσαύξηση από <b>' + categoryAmount.fromCategory + ': ' + this.getTotalAmountCorrectNumberFormat(categoryAmount.amount) + '</b>'
      });
      return str;
    }
    return '';
  }

  validateAfm(afm: string){
    if (!afm.match(/^\d{9}$/) || afm == '000000000')
        return false;

    var m = 1, sum = 0;
    for (var i = 7; i >= 0; i--) {
        m *= 2;
        sum += +afm.charAt(i) * m;
    }

    return sum % 11 % 10 == +afm.charAt(8);

    // References
    // https://gist.github.com/tdoumas/7875550
    // http://epixeirisi.gr/%CE%9A%CE%A1%CE%99%CE%A3%CE%99%CE%9C%CE%91-%CE%98%CE%95%CE%9C%CE%91%CE%A4%CE%91-%CE%A6%CE%9F%CE%A1%CE%9F%CE%9B%CE%9F%CE%93%CE%99%CE%91%CE%A3-%CE%9A%CE%91%CE%99-%CE%9B%CE%9F%CE%93%CE%99%CE%A3%CE%A4%CE%99%CE%9A%CE%97%CE%A3/23791/%CE%91%CF%81%CE%B9%CE%B8%CE%BC%CF%8C%CF%82-%CE%A6%CE%BF%CF%81%CE%BF%CE%BB%CE%BF%CE%B3%CE%B9%CE%BA%CE%BF%CF%8D-%CE%9C%CE%B7%CF%84%CF%81%CF%8E%CE%BF%CF%85
  }

  getAmountInGrFormat(amount: number, includeEuroSign: boolean) {
    amount = Math.round((amount + Number.EPSILON) *100)/100;
    let euroSign = includeEuroSign ? '€' : '';
    let n = new Intl.NumberFormat('el-GR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(amount) + euroSign;
    return n;
  }

  getPercentageInGrFormat(amount: number, includePercentage: boolean) {
    let percentageSign = includePercentage ? '%' : '';
    let n = new Intl.NumberFormat('el-GR', {minimumFractionDigits: 11, maximumFractionDigits: 11} ).format(amount) + percentageSign;
    return n;
  }

  onGeneralInputChange(value) {  
    this.noChangesApplied = false;
  }

  onExportToExcelClicked() {
    // if (!this.noChangesApplied){
    //   Swal({
    //     title: this.translationService.translate("distribution-form-screen.exportSwalTitle"),
    //     text: this.translationService.translate("distribution-form-screen.exportSwalSubtitle"),
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     confirmButtonText: this.translationService.translate("distribution-form-screen.export"),
    //     cancelButtonColor: '#d33',
    //     cancelButtonText: this.translationService.translate("distribution-form-screen.cancel"),
    //     allowOutsideClick: true,
    //     showLoaderOnConfirm: true,
    //     preConfirm: () => {
    //       return new Promise((resolve) => {
    //         this.excelLoading = true;
    //         var requestModel: ExportRequestDTO = new ExportRequestDTO();
    //         requestModel.distributionTableUniqueCode = this.distribution.uniqueCode;
    //         requestModel.distributionRequests = this.distributionRequests;
    //         requestModel.amountDistributions = this.amountsResult;
    //         requestModel.lenderDistributions = this.lenderDistributionWrappers;
    //         this.generalService.exportDistribution(Constants.EXPORT_DISTRIBUTION_TO_EXCEL_URL, requestModel, null, null).subscribe(this.exportToExcelCallback, this.exportToExcelCallbackError);
    //       });
    //     }
    //   });
    // } else {
    // }
    this.excelLoading = true;
    var requestModel: ExportRequestDTO = new ExportRequestDTO();
    requestModel.distributionTableUniqueCode = this.distribution.uniqueCode;
    requestModel.distributionRequests = this.distributionRequests;
    requestModel.amountDistributions = this.amountsResult;
    requestModel.lenderDistributions = this.lenderDistributionWrappers;
    this.generalService.exportDistribution(Constants.EXPORT_DISTRIBUTION_TO_EXCEL_URL, requestModel, null, null).subscribe(this.exportToExcelCallback, this.exportCallbackError);
  }

  exportToExcelCallback = (response) => {
    const fileName = 'Πίνακας_Κατάταξης_' + this.distribution.uniqueCode;
    this.generalService.downloadExcelFile(response, 'application/vnd.ms-excel', fileName);
    this.excelLoading = false;
  }

  onExportToWordClicked() {
    // if (!this.noChangesApplied){
    //   Swal({
    //     title: this.translationService.translate("distribution-form-screen.exportSwalTitle"),
    //     text: this.translationService.translate("distribution-form-screen.exportSwalSubtitle"),
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     confirmButtonText: this.translationService.translate("distribution-form-screen.export"),
    //     cancelButtonColor: '#d33',
    //     cancelButtonText: this.translationService.translate("distribution-form-screen.cancel"),
    //     allowOutsideClick: true,
    //     showLoaderOnConfirm: true,
    //     preConfirm: () => {
    //       return new Promise((resolve) => {
    //         this.wordLoading = true;
    //       });
    //     }
    //   });
    // } else {
    // }
    this.wordLoading = true;
    var requestModel: ExportRequestDTO = new ExportRequestDTO();
    requestModel.distributionTableUniqueCode = this.distribution.uniqueCode;
    requestModel.distributionRequests = this.distributionRequests;
    requestModel.amountDistributions = this.amountsResult;
    requestModel.lenderDistributions = this.lenderDistributionWrappers;
    this.generalService.exportDistribution(Constants.EXPORT_DISTRIBUTION_TO_WORD_URL, requestModel, null, null).subscribe(this.exportToWordCallback, this.exportCallbackError);
  }
  
  exportToWordCallback = (response) => {
    const fileName = 'Πίνακας_Κατάταξης_' + this.distribution.uniqueCode;
    this.generalService.downloadWordFile(response, 'application/msword', fileName);
    this.wordLoading = false;
  }

  exportCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.excelLoading = false;
    this.wordLoading = false;
  };

  onExitClicked() {
    this.router.navigate(['/distributions']);
  }
}

export class ClaimType {
  id: string;
  title: string;
  canSelectDate: boolean;
  parentId: string;

  public constructor(id: string, title: string, canSelectDate: boolean, parentId: string){
    this.id = id
    this.title = title
    this.canSelectDate = canSelectDate
    this.parentId = parentId
  }
}


      // set lenderDistributionWrappers OLD
      // this.amountsResult.forEach(amountResult => {
      //   if (amountResult.categoryDistributions != null && amountResult.categoryDistributions.length > 0){
      //     amountResult.categoryDistributions.forEach(catDistr => {
      //       var existingCategory = allCategoryDistributions.find(c => c.title == catDistr.title);
      //       if (existingCategory != null){
      //         // parent exists
      //         // go through children
      //         catDistr.claimDistributions.forEach(newClaim => {
      //           var existingClaim = existingCategory.claimDistributions.find(c => c.title == newClaim.title);
      //           if (existingClaim != null){
      //             // if children exists add amount
      //             existingCategory.claimDistributions[existingCategory.claimDistributions.indexOf(existingClaim)].amount += newClaim.amount;
      //           } else {
      //             // else add new child
      //             allCategoryDistributions[allCategoryDistributions.indexOf(existingCategory)].claimDistributions.push(newClaim);
      //           }
      //         });
      //       } else {
      //         // push category distribution in correct place
      //         if (catDistr.title.startsWith('Περίπτωση 975-')){
      //           var all975 = allCategoryDistributions.filter(cd => cd.title.startsWith('Περίπτωση 975-'));
      //           if (all975 != null && all975.length > 0){
      //             allCategoryDistributions.splice(allCategoryDistributions.indexOf(all975[all975.length-1])+1, 0, catDistr);
      //           } else {
      //             allCategoryDistributions.splice(0, 0, catDistr);
      //           }
      //         } else if (catDistr.title.startsWith('Περίπτωση 976-')) {
      //           var all976 = allCategoryDistributions.filter(cd => cd.title.startsWith('Περίπτωση 976-'));
      //           if (all976 != null && all976.length > 0){
      //             allCategoryDistributions.splice(allCategoryDistributions.indexOf(all976[all976.length-1])+1, 0, catDistr);
      //           } else {
      //             var all975 = allCategoryDistributions.filter(cd => cd.title.startsWith('Περίπτωση 975-'));
      //             if (all975 != null && all975.length > 0){
      //               // if there are 975 claims, push it below last 975
      //               allCategoryDistributions.splice(allCategoryDistributions.indexOf(all975[all975.length-1])+1, 0, catDistr);
      //             } else {
      //               if (allCategoryDistributions.length > 1){
      //                 // else if there is more than one item in array put it in the penultimate position ( second last)
      //                 allCategoryDistributions.splice(allCategoryDistributions.length-1, 0, catDistr); //last object is always manuscript lenders so we put the first 976 claim in the penultimate position ( second last)
      //               } else {
      //                 // else is the first one so just push it
      //                 allCategoryDistributions.push(catDistr);
      //               }
      //             }
      //           }
      //         } else {
      //           // manuscript lenders will be displayed last
      //           allCategoryDistributions.push(catDistr);
      //         } 
      //       }
      //     });
      //   }
      // });