import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef
} from "@angular/core";
import { Router } from "@angular/router";
import { Column } from "app/models/Column";
import { SearchTransfersFiltersDTO } from "app/models/SearchTransfersFiltersDTO";
import { SearchTransfersRequestDTO } from "app/models/SearchTransfersRequestDTO";
import { TransferDTO } from "app/models/TransferDTO";
import { GeneralService } from "app/services/general.service";
import { TranslationService } from "app/services/translation.service";
import { SwalType, UtilsService } from "app/services/utils.service";
import { AuthService } from "app/shared/auth/auth.service";
import { Constants } from "app/utils/constants.config";
import * as moment from "moment/moment";
import { Subscription } from "rxjs";

moment.locale("el_GR");
@Component({
  selector: "app-transfers",
  templateUrl: "./transfers.component.html",
  styleUrls: ["./transfers.component.scss"]
})
export class TransfersComponent implements OnInit, OnDestroy {
  rows: TransferDTO[];
  totalRows: number;
  totalParts: number;//used for ui
  isLoading: boolean = true;
  columns: Column[];
  limit = Constants.PAGING_LIMIT;
  currentPage: number;
  todayDate : any;
  startDate : string;
  endDate : string;
  parentScreen = Constants.Screens.TRANSFERS_SCREEN;
  @ViewChild('afmDebtorId') afmDebtorElement:ElementRef;
  @ViewChild('uniqueCodeId') uniqueCodeElement:ElementRef;

  dateRangePickerLocale = {
    applyLabel : this.translationService.translate("general.apply"),
    clearLabel : this.translationService.translate("general.clearShort"),
    format: "DD/MM/YYYY"
  };
  searchTransfersSubscription: Subscription = null;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private generalService: GeneralService,
    private authService: AuthService,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    // This line is to be able to use (onSameUrlNavigation), to reload on the same page
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.todayDate = moment();
    this.onSearchTransfers();
  }

  ngOnDestroy() {
    if (this.searchTransfersSubscription) {
      this.searchTransfersSubscription.unsubscribe();
    }
  }

  onSearchTransfers() {
    this.isLoading = true;
    this.rows = new Array();
    this.totalRows = 0;
    if (this.searchTransfersSubscription){
      this.searchTransfersSubscription.unsubscribe();
    }

    let searchTransfersFiltersDTO : SearchTransfersFiltersDTO = {
      dateFrom : this.startDate!=null ? this.startDate : "",
      dateTo : this.endDate!=null ? this.endDate : "",
      debtorVatNumber : this.afmDebtorElement.nativeElement.value!=null ? this.afmDebtorElement.nativeElement.value : "",
      uniqueCode : this.uniqueCodeElement.nativeElement.value!=null ? this.uniqueCodeElement.nativeElement.value : ""
    };

    let searchTransfersRequestDTO : SearchTransfersRequestDTO = {
        searchFilters : searchTransfersFiltersDTO,
        loggedInUser: this.authService.getLoggedInUser()
    }

    this.searchTransfersSubscription = this.generalService
      .postRequest(
        Constants.SEARCH_TRANSFERS_URL,
        searchTransfersRequestDTO,
        null,
        null
      )
      .subscribe(this.searchTransfersCallback, this.searchTransfersCallbackError);
  }

  searchTransfersCallback = (response) => {
    if (response.success) {
      this.columns = response.resource!=null ? response.resource.columns: new Array();
      this.currentPage = 0;
      this.totalRows = response.resource!=null && response.resource.data!=null? response.resource.data.length : 0;
      this.rows = response.resource!=null && response.resource.data!=null? response.resource.data : new Array();
      this.totalParts = 0;
      if(this.rows != null &&  this.rows.length > 0 ){
        this.rows.forEach(transfer => {
          this.totalParts += transfer.transferedParts;
        });
      }
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isLoading = false;
  };

  searchTransfersCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isLoading = false;
  };
  
  // onDateChanged(event) {
  //   if (event) {
  //     this.startDate = event.startDate!=null?event.startDate.format("YYYY-MM-DD"):null;
  //     this.endDate = event.endDate!=null?event.endDate.format("YYYY-MM-DD"):null;
  //   }
  // }

  onStartDateChanged(event) {
    if (event) {
      this.startDate = event.startDate!=null?event.startDate.format("YYYY-MM-DD"):null;
    }
  }

  onEndDateChanged(event){
    if(event){
      this.endDate = event.endDate!=null?event.endDate.format("YYYY-MM-DD"):null;
    }
  }

  onSortByDateClicked(event){
    const propertyName = event.propertyName;
    const type = event.type;
    if(propertyName == "transferDateFormatted" && type == "desc"){
      this.rows.sort((a, b) => {
        return <any>new Date(b.transferDate) - <any>new Date(a.transferDate);
      });
    }
    if(propertyName == "transferDateFormatted" && type == "asc"){
      this.rows.sort((a, b) => {
        return <any>new Date(a.transferDate) - <any>new Date(b.transferDate);
      });
    }
  }

}
