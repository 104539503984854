import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef
} from "@angular/core";
import { Router } from "@angular/router";
import { Column } from "app/models/Column";
import { GeneralService } from "app/services/general.service";
import { SwalType, UtilsService } from "app/services/utils.service";
import { AuthService } from "app/shared/auth/auth.service";
import { Constants } from "app/utils/constants.config";
import * as moment from "moment/moment";
import { Subscription } from "rxjs";
import { SearchHistoryFiltersDTO } from "app/models/SearchHistoryFiltersDTO";
import { SearchHistoryRequestDTO } from "app/models/SearchHistoryRequestDTO";
import { HistoryDTO } from "app/models/HistoryDTO";
import { TranslationService } from "app/services/translation.service";

moment.locale("el_GR");
@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"]
})
export class HistoryComponent implements OnInit, OnDestroy {
  rows: HistoryDTO[];
  totalRows: number;
  isLoading: boolean = true;
  columns: Column[];
  limit = Constants.PAGING_LIMIT;
  currentPage: number;
  todayDate : any;
  startDate : string;
  endDate : string;
  parentScreen = Constants.Screens.HISTORY_SCREEN;
  @ViewChild('afmDebtorId') afmDebtorElement:ElementRef;
  @ViewChild('uniqueCodeId') uniqueCodeElement:ElementRef;

  dateRangePickerLocale = {
    applyLabel : this.translationService.translate("general.apply"),
    clearLabel : this.translationService.translate("general.clearShort"),
    format: "DD/MM/YYYY"
  };
  searchHistorySubscription: Subscription = null;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private generalService: GeneralService,
    private authService: AuthService,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    // This line is to be able to use (onSameUrlNavigation), to reload on the same page
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.todayDate = moment();
    this.onSearchHistory();
  }

  ngOnDestroy() {
    if (this.searchHistorySubscription) {
      this.searchHistorySubscription.unsubscribe();
    }
  }

  onSearchHistory() {
    this.isLoading = true;
    this.rows = new Array();
    this.totalRows = 0;
    if (this.searchHistorySubscription){
      this.searchHistorySubscription.unsubscribe();
    }

    let searchHistoryFiltersDTO : SearchHistoryFiltersDTO = {
      dateFrom : this.startDate!=null ? this.startDate : "",
      dateTo : this.endDate!=null ? this.endDate : "",
      debtorVatNumber : this.afmDebtorElement.nativeElement.value!=null ? this.afmDebtorElement.nativeElement.value : "",
      uniqueCode : this.uniqueCodeElement.nativeElement.value!=null ? this.uniqueCodeElement.nativeElement.value : ""
    };

    let searchHistoryRequestDTO : SearchHistoryRequestDTO = {
        searchFilters : searchHistoryFiltersDTO,
        loggedInUser: this.authService.getLoggedInUser()
    }

    this.searchHistorySubscription = this.generalService
      .postRequest(
        Constants.SEARCH_HISTORY_URL,
        searchHistoryRequestDTO,
        null,
        null
      )
      .subscribe(this.searchHistoryCallback, this.searchHistoryCallbackError);
  }

  searchHistoryCallback = (response) => {
    if (response.success) {
      this.columns = response.resource!=null ? response.resource.columns: new Array();
      this.currentPage = 0;
      this.totalRows = response.resource!=null && response.resource.data!=null? response.resource.data.length : 0;
      this.rows = response.resource!=null && response.resource.data!=null? response.resource.data : new Array();
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isLoading = false;
  };

  searchHistoryCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isLoading = false;
  };
  
  onStartDateChanged(event) {
    if (event) {
      this.startDate = event.startDate!=null?event.startDate.format("YYYY-MM-DD"):null;
    }
  }

  onEndDateChanged(event){
    if(event){
      this.endDate = event.endDate!=null?event.endDate.format("YYYY-MM-DD"):null;
    }
  }

  onSortByDateClicked(event){
    const propertyName = event.propertyName;
    const type = event.type;
    if(propertyName == "actionDateFormatted" && type == "desc"){
      this.rows.sort((a, b) => {
        return <any>new Date(b.actionDate) - <any>new Date(a.actionDate);
      });
    }
    if(propertyName == "actionDateFormatted" && type == "asc"){
      this.rows.sort((a, b) => {
        return <any>new Date(a.actionDate) - <any>new Date(b.actionDate);
      });
    }
  }

}
