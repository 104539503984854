import { Component, OnInit } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  public isCollapsed = true;

  constructor(private authService: AuthService, private translationService: TranslationService) {
  }

  ngOnInit() {
    setTimeout(() => {
      const wrapperDiv = document.getElementsByClassName('wrapper')[0];
      if (wrapperDiv != null) {
        const dir = wrapperDiv.getAttribute('dir');
        if (dir === 'rtl') {
          this.placement = 'bottom-left';
        } else if (dir === 'ltr') {
          this.placement = 'bottom-right';
        }
      }
    }, 3000);
  }

  ChangeLanguage(language: string) {
    this.translationService.setLanguage(language);
  }

  logout() {
    this.authService.logout();
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }
}
