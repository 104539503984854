import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/shared/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService, SwalType } from 'app/services/utils.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm = new FormGroup({
    emailControl: new FormControl("", [Validators.required]),
    passwordControl: new FormControl("", [Validators.required])
  });

  public windowWidthDynamic: any;
  signInUserSubscription: Subscription;
  queryParamsSubscription: Subscription;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.windowWidthDynamic = window.innerWidth;
  }

  singleOfferRedirect: string = "";
  offerId: string = "";

  viewPass: boolean = false;
  passType: string = "password";

  email: string;
  password: string;
  return: string;
  isLoading = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private utilsService: UtilsService
  ) {
   
  }
  ngOnDestroy(): void {
    if (this.signInUserSubscription) this.signInUserSubscription.unsubscribe();
    if (this.queryParamsSubscription){
      this.queryParamsSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.authService.getLoggedInUser() !== null) {
      this.router.navigate(["/home"], {});
    }
    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => (this.return = params["return"] || "/home")
    );
    this.windowWidthDynamic = window.innerWidth;
  }

  onSubmit() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.authService.removeSavedValues();
      this.email = this.loginForm.controls["emailControl"].value;
      this.password = this.loginForm.controls["passwordControl"].value;
      if (this.signInUserSubscription){
        this.signInUserSubscription.unsubscribe();
      }
      this.signInUserSubscription = this.authService
        .signinUser(this.email, this.password)
        .subscribe(this.signInUserCallback, this.signInUserErrorCallback);
    }
  }

  signInUserCallback = (response) => {
    if (response.success) {
      if (response.validCredentials) {
        if (response.resource.username != null && response.resource.username.length > 0 && response.resource.roleName != null && response.resource.roleName.length > 0) {
          this.authService.saveLoggedInUser(response.resource);
          this.loginForm.reset();
          this.email = null;
          this.password = null;
          this.router.navigateByUrl(this.return);
        }
        else {
          this.utilsService.showSwal(this.translationService.translate('general.something-went-wrong'), SwalType.Error);
        }
      } else {
        this.utilsService.showSwal(this.translationService.translate('login-screen.invalid-credentials'), SwalType.Error);
      }
    } else {
      this.utilsService.showSwal(this.translationService.translate('login-screen.not-active-user'),SwalType.Error)
      // this.utilsService.showSwal(response.errorMessage, SwalType.Error);//TODO:: Might need to be re-implemented from the backend since success failure doesnt mean not ana ctive user
    }
    this.isLoading = false;
  };

  signInUserErrorCallback = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isLoading = false;
  };

  showPass() {
    this.viewPass = !this.viewPass;

    if (this.viewPass) {
      this.passType = "text";
    } else {
      this.passType = "password";
    }
  }
}
