export class AuctionPartDTO {
    auctionPartId: number;
    uniqueCode: string;
    debtor: string;
    hastener: string;
    type: string;
    municipality: string;
    address: string;
    eventDate: string;
    postingDate: string;
    currentPart: number;

    //custom fields
    eventDateFormatted : string;
    postingDateFormatted : string;
  }
