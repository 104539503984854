import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class TranslationService {
  constructor(private translateService: TranslateService) {
    translateService.addLangs(["en", "gr"]);
    translateService.setDefaultLang("gr");
    const browserLang: string = translateService.getBrowserLang();
    //translateService.use(browserLang.match(/en|gr/) ? browserLang : "gr");
    translateService.use("gr");
  }

  translate(resourceKey: string) {
    return this.translateService.instant(resourceKey);
  }

  setLanguage(language: string) {
    return this.translateService.use(language);
  }

  getApiLanguage() {
    const browserLang: string = this.translateService.getBrowserLang();
    //var lang = browserLang.match(/en|gr/) ? browserLang : "gr";
    var lang = "gr";
    if (lang!=null && lang.toLowerCase()==="en"){
      return "en-US";
    }
    else{
      return "el-GR";
    }
  }

}
