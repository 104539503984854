import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GeneralService } from "app/services/general.service";
import { SwalType, UtilsService } from "app/services/utils.service";
import { AuthService } from "app/shared/auth/auth.service";
import { Constants } from "app/utils/constants.config";
import { Subscription } from "rxjs";
import { HideUnhideAuctionRequestDTO } from "app/models/HideUnhideAuctionRequestDTO";
import { InsertAuctionToEauctionsRequestDTO } from "app/models/InsertAuctionToEauctionsRequestDTO";
import { AuctionDetailsDTO } from "app/models/AuctionDetailsDTO";
import { AuctionDetailsRequestDTO } from "app/models/AuctionDetailsRequestDTO";
import { TranslationService } from "app/services/translation.service";

@Component({
  selector: "auction-details",
  templateUrl: "./auction-details.component.html",
  styleUrls: ["./auction-details.component.css"]
})
export class AuctionDetailsComponent implements OnInit, OnDestroy {
  parentScreen = Constants.Screens.AUCTION_DETAILS_SCREEN;
  auction: AuctionDetailsDTO = null;
  isLoading: boolean = true;
  isAuctionDetailsReportLoading : boolean = false;
  isInsertLoading: boolean = false;
  isHideUnhideLoading: boolean = false;

  getAuctionDetailsSubscription: Subscription = null;
  insertAuctionToEauctionsSubscription: Subscription = null;
  hideUnhideAuctionSubscription: Subscription = null;
  queryParamsSubscription: Subscription = null;
  isLoggedInUserAuctioneer : boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private generalService: GeneralService,
    private authService: AuthService,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    // This line is to be able to use (onSameUrlNavigation), to reload on the same page
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.isLoggedInUserAuctioneer = this.utilsService.isAuctioneer(this.authService.getLoggedInUser());

    this.queryParamsSubscription = this.route.queryParams.subscribe(
      params => {
        let selectedAuctionCode = params["code"];
        if (selectedAuctionCode!=null && selectedAuctionCode.length>0){
          selectedAuctionCode = this.utilsService.decryptData(selectedAuctionCode);
          if (selectedAuctionCode!=null && selectedAuctionCode.length>0){
            this.onGetAuctionDetails(selectedAuctionCode);
          } 
          else{
            this.auction = null;
            this.isLoading = false;
          }
        }
        else{
          this.auction = null;
          this.isLoading = false;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.queryParamsSubscription){
      this.queryParamsSubscription.unsubscribe();
    }
    if (this.getAuctionDetailsSubscription) {
      this.getAuctionDetailsSubscription.unsubscribe();
    }
    if (this.insertAuctionToEauctionsSubscription) {
      this.insertAuctionToEauctionsSubscription.unsubscribe();
    }
    if (this.hideUnhideAuctionSubscription){
      this.hideUnhideAuctionSubscription.unsubscribe();
    }
  }

  onGetAuctionDetails(selectedAuctionCode) {
    this.isLoading = true;
    this.auction = null;
    if (this.getAuctionDetailsSubscription){
      this.getAuctionDetailsSubscription.unsubscribe();
    }
    let loggedInUser = this.authService.getLoggedInUser();
    let auctionDetailsRequestDTO : AuctionDetailsRequestDTO = {
        auctionCode : selectedAuctionCode,
        loggedInUser : loggedInUser
    }

    this.getAuctionDetailsSubscription = this.generalService
      .postRequest(
        Constants.GET_AUCTION_DETAILS_URL,
        auctionDetailsRequestDTO,
        null,
        null
      )
      .subscribe(this.getAuctionDetailsCallback, this.getAuctionDetailsCallbackError);
  }

  getAuctionDetailsCallback = (response) => {
    if (response.success) {
      this.auction = response.resource;
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isLoading = false;
  };

  getAuctionDetailsCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isLoading = false;
  };

  insertAuctionToEauctions(){
    if (this.auction!=null) {
      if (!this.isInsertLoading) {
        this.isInsertLoading = true;
        if (this.insertAuctionToEauctionsSubscription) {
          this.insertAuctionToEauctionsSubscription.unsubscribe();
        }

        let insertAuctionToEauctionsRequestDTO = new InsertAuctionToEauctionsRequestDTO();
        insertAuctionToEauctionsRequestDTO.auctionCodes = new Array();
        insertAuctionToEauctionsRequestDTO.auctionCodes.push(this.auction.uniqueCode);
        let loggedInUser = this.authService.getLoggedInUser();
        insertAuctionToEauctionsRequestDTO.userId = loggedInUser != null ? loggedInUser.userId : null;
        insertAuctionToEauctionsRequestDTO.loggedInUser = loggedInUser;

        this.insertAuctionToEauctionsSubscription = this.generalService
          .postRequest(
            Constants.INSERT_AUCTIONS_TO_EAUCTIONS_URL,
            insertAuctionToEauctionsRequestDTO,
            null,
            null
          )
          .subscribe(this.insertAuctionToEauctionsCallback, this.insertAuctionToEauctionsCallbackError);
      }
    }
  }

  insertAuctionToEauctionsCallback = (response) => {
    if (response.success) {
      this.utilsService.showSwal(this.translationService.translate("auction-details-screen.auction-is-inserted-successfully"), SwalType.Success);
      setTimeout(()=>{
        this.router.navigate(["/home"], {replaceUrl:true});
      });
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isInsertLoading = false;
  };

  insertAuctionToEauctionsCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isInsertLoading = false;
  };

  onHideUnHideClicked() {
    if (this.auction!=null){
      if (!this.isHideUnhideLoading) {
        this.isHideUnhideLoading = true;
        if (this.hideUnhideAuctionSubscription) {
          this.hideUnhideAuctionSubscription.unsubscribe();
        }

        let loggedInUser = this.authService.getLoggedInUser();
        let hideUnhideAuctionRequestDTO : HideUnhideAuctionRequestDTO = {
          auctionCode : this.auction.uniqueCode,
          loggedInUser : loggedInUser,
          hide: !this.auction.isHidden
        }

        this.hideUnhideAuctionSubscription = this.generalService
          .postRequest(
            Constants.HIDE_UNHIDE_AUCTION_URL,
            hideUnhideAuctionRequestDTO,
            null,
            null
          )
          .subscribe(this.hideUnhideCallback, this.hideUnhideCallbackError);
      }
    }
  }

  hideUnhideCallback = (response) => {
    if (response.success) {
      setTimeout(()=>{
        this.router.navigate(["/home"], {replaceUrl:true});
      });
    } else {
      this.utilsService.showSwal(response.errorMessage, SwalType.Error);
    }
    this.isHideUnhideLoading = false;
  };

  hideUnhideCallbackError = (error: HttpErrorResponse) => {
    this.authService.handleErrorResponse(error);
    this.utilsService.showSwal(error.error, SwalType.Error);
    this.isHideUnhideLoading = false;
  };

  downloadAuctionDetailsReport(){
    this.isAuctionDetailsReportLoading = true;
    if (this.auction != null && this.auction.filesPath != null && this.auction.filesPath.length > 0) {
      window.open(this.auction.filesPath, "_blank");
    }
    this.isAuctionDetailsReportLoading = false;
  }

}
